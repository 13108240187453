<template>
  <div class="candidate-alert rag-dashboard" id="rag_dashboard">
    <CCard>
      <CCardBody>
        <div
          class="d-flex align-items-center"
          :class="{ 'cursor-pointer': isCollapse }"
          @click="isCollapse ? (showAlert = !showAlert) : null"
        >
          <em
            v-if="isCollapse"
            class="fa-solid text-primary mb-2"
            :class="showAlert ? 'fa-angle-down' : 'fa-angle-right'"
          />
          <h5 class="text-primary mx-2 mb-2">
            Alert 1 - Job and Candidate Status
          </h5>
        </div>
        <!-- <h5 class="text-primary">Alert 1 - Job and Candidate Status</h5> -->
        <div v-if="showAlert">
          <CTabs
            variant="tabs"
            :active-tab="activeTab"
            @update:activeTab="onTabChange"
          >
            <CTab
              v-if="!isCandidateSupplierFromAccessToken"
              :title="`Job Approval (${getDashboardTabCount.job_approval})`"
            >
              <JobApproval
                v-if="displayTab.job_approval"
                :collapseIndex="getCollapseIndex"
                :collapseDuration="collapseDuration"
                :isTransactionFetch="isTransactionFetch"
                @toggleClick="toggleDetails"
                @toJob="navToJob"
                @editJobClick="editJob"
                @completeClicked="markAsReviewed"
                @toJobBoard="navigateToJobBoard"
                @jobReapprovalstateClicked="JobReapprovalstateClicked"
                @openJobInfoModal="openJobInfoModal"
              />
            </CTab>
            <CTab :title="`Job Expiry (${getJobExpiry.length})`">
              <div
                class="documentScrolling document-table position-relative"
                v-if="displayTab.job_expiry"
              >
                <CDataTable
                  :striped="false"
                  :bordered="false"
                  :small="true"
                  :items="getJobExpiry"
                  :fields="jobExpiryFields"
                  :items-per-page="10"
                  :sorter="false"
                  :tableFilter="false"
                  pagination
                  class="p-2"
                >
                  <template #check_box-header>
                    <input
                      type="checkbox"
                      :name="`checkbox`"
                      :value="selectedJobID.length === getJobExpiry.length"
                      :checked="
                        (getJobExpiry.length &&
                          selectedJobID.length === getJobExpiry.length) ||
                        false
                      "
                      @click="handleCheckAllJobID($event.target.checked)"
                    />
                    <span class="mx-1">Select All</span>
                  </template>
                  <template #check_box="{ item }">
                    <td>
                      <input
                        type="checkbox"
                        :name="`checkbox-${item.job_id}`"
                        :value="selectedJobID.includes(item.job_id)"
                        :checked="selectedJobID.includes(item.job_id) || false"
                        @click="
                          handleCheckJobID(item.job_id, $event.target.checked)
                        "
                      />
                    </td>
                  </template>
                  <template #job_id="{ item }">
                    <td style="padding: 0">
                      <tr>
                        <td
                          width="25%"
                          style="border: none !important"
                          class="px-1"
                        >
                          <span
                            class="circle"
                            :style="`background-color: ${item.circle_color} !important`"
                          ></span>
                        </td>
                        <td
                          width="75%"
                          style="border: none !important"
                          class="px-1"
                        >
                          <span
                            style="color: #2678ce; font-weight: 600"
                            class="clickable-action"
                            @click="navToJob(item.job_id)"
                            >{{ item.display_uid }}</span
                          >
                          <span
                            class="ml-2 cursor-pointer"
                            v-c-tooltip="{
                              content: 'Click to View Job Details',
                              placement: 'left',
                              appendToBody: true,
                            }"
                            @click="openJobInfoModal(item.job_id)"
                            ><i class="fa-solid fa-circle-info text-primary"></i
                          ></span>
                        </td>
                      </tr>
                    </td>
                  </template>
                  <template #job_title="{ item }">
                    <td>
                      {{ item.job_title }}
                      <span class="text-nowrap">{{
                        item.job_organisation_name
                          ? `[${item.job_organisation_name}]`
                          : ""
                      }}</span>
                    </td>
                  </template>
                  <template #action="{ item }">
                    <td class="py-2">
                      <div class="d-flex">
                        <CButton
                          color="primary"
                          square
                          size="sm"
                          class="mr-1"
                          @click="updateJobExpiry(item)"
                        >
                          Update Expiry
                        </CButton>
                      </div>
                    </td>
                  </template>
                  <template #no-items-view>
                    <h5 class="text-center" :style="'color:'">
                      No Data Found
                      <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                    </h5>
                  </template>
                </CDataTable>
                <div
                  class="
                    cover
                    position-absolute
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                  v-if="
                    isJobSupplierFromAccessToken ||
                    isCandidateSupplierFromAccessToken
                  "
                >
                  <h5
                    class="
                      text-center text-danger
                      mb-4
                      p-3
                      border border-danger
                      rounded-lg
                      font-weight-bold
                    "
                  >
                    Full TalentFind License Required<span class="d-block"
                      >contact@talentfindsolutions.com</span
                    >
                  </h5>
                </div>
              </div>
            </CTab>
            <CTab
              v-if="isJobSupplierFromAccessToken || isUKMainAgency"
              :title="`CV Request (${getDashboardTabCount.cv_request})`"
            >
              <CVRequest
                v-if="displayTab.cv_request"
                :pendingCVRequest="pendingCVRequest"
                :completedCVRequest="completedCVRequest"
                @toCandidate="navToCandidate"
                @openCandidateInfoModal="openCandidateInfoModal"
              />
            </CTab>
            <CTab :title="`Submission (${getDashboardTabCount.submission})`">
              <CandidateSubmission
                v-if="displayTab.submission"
                :collapseIndex="getCollapseIndex"
                :collapseDuration="collapseDuration"
                :isTransactionFetch="isTransactionFetch"
                @toggleClick="toggleDetails"
                @toJob="navToJob"
                @toCandidate="navToCandidate"
                @resubmitCandidateClick="onResubmitCandidate"
                @completeClicked="markAsReviewed"
                @reinstateClicked="onReinstateClicked"
                @vaultstateClicked="onVaultstateClicked"
                @openCandidateInfoModal="openCandidateInfoModal"
                @openJobInfoModal="openJobInfoModal"
                @actionModalEvent="actionModalEvent"
                @openCheckList="openCheckList"
              />
            </CTab>
            <CTab :title="`Schedule (${getDashboardTabCount.schedule})`">
              <CandidateSchedule
                v-if="displayTab.schedule"
                :collapseIndex="getCollapseIndex"
                :collapseDuration="collapseDuration"
                :isTransactionFetch="isTransactionFetch"
                @toggleClick="toggleDetails"
                @toJob="navToJob"
                @toCandidate="navToCandidate"
                @requestInterviewClicked="onRequestInterview"
                @interviewScheduleClicked="onInterviewSchedule"
                @openEmailModal="openEmailModal"
                @openCandidateInfoModal="openCandidateInfoModal"
                @openJobInfoModal="openJobInfoModal"
                @actionModalEvent="actionModalEvent"
                @openCheckList="openCheckList"
              />
            </CTab>
            <CTab :title="`Interview (${getDashboardTabCount.interview})`">
              <CandidateInterview
                v-if="displayTab.interview"
                :collapseIndex="getCollapseIndex"
                :collapseDuration="collapseDuration"
                :isTransactionFetch="isTransactionFetch"
                @toggleClick="toggleDetails"
                @toJob="navToJob"
                @toCandidate="navToCandidate"
                @completeClicked="markAsReviewed"
                @openCandidateInfoModal="openCandidateInfoModal"
                @openJobInfoModal="openJobInfoModal"
                @openInterviewFeedback="openInterviewFeedback"
                @actionModalEvent="actionModalEvent"
                @openCheckList="openCheckList"
              />
            </CTab>
            <CTab :title="`Offer (${getDashboardTabCount.offered})`">
              <CandidateOffered
                v-if="displayTab.offered"
                :collapseIndex="getCollapseIndex"
                :collapseDuration="collapseDuration"
                :isTransactionFetch="isTransactionFetch"
                @toggleClick="toggleDetails"
                @toJob="navToJob"
                @toCandidate="navToCandidate"
                @completeClicked="markAsReviewed"
                @documentsClicked="documentUploadforOffer"
                @completeOfferClicked="movetoonboard"
                @viewDocument="viewDocument_forCandidate"
                @viewDocumentDetails="onViewDocuments"
                @offerViewDocumentDetails="onViewOfferDocuments"
                @openCandidateInfoModal="openCandidateInfoModal"
                @openJobInfoModal="openJobInfoModal"
                @openInterviewFeedback="openInterviewFeedback"
                @actionModalEvent="actionModalEvent"
                @openCheckList="openCheckList"
              />
            </CTab>
            <CTab :title="`OnBoard (${getDashboardTabCount.onboard})`">
              <CandidateOnboard
                v-if="displayTab.onboard"
                :collapseIndex="getCollapseIndex"
                :collapseDuration="collapseDuration"
                :isTransactionFetch="isTransactionFetch"
                @toggleClick="toggleDetails"
                @toJob="navToJob"
                @toCandidate="navToCandidate"
                @completeClicked="markAsReviewed"
                @viewDocument="viewDocument_forCandidate"
                @viewDocumentDetails="onViewDocuments"
                @iniateJoining="moveToJoining"
                :candidate_document_id="can_document_id"
                @openCandidateInfoModal="openCandidateInfoModal"
                @openJobInfoModal="openJobInfoModal"
                @openInterviewFeedback="openInterviewFeedback"
                @actionModalEvent="actionModalEvent"
                @documentsClicked="documentUploadforOnBoard"
                @openCheckList="openCheckList"
              />
            </CTab>
            <CTab :title="`Joined (${getDashboardTabCount.joining})`">
              <CandidateJoining
                v-if="displayTab.joining"
                :collapseIndex="getCollapseIndex"
                :collapseDuration="collapseDuration"
                :isTransactionFetch="isTransactionFetch"
                @toggleClick="toggleDetails"
                @toJob="navToJob"
                @toCandidate="navToCandidate"
                @viewDocument="viewDocument_forCandidate"
                :candidate_document_id="can_document_id"
                @joiningViewDocumentDetails="joiningViewDocumentDetails"
                @openCandidateInfoModal="openCandidateInfoModal"
                @openJobInfoModal="openJobInfoModal"
                @openInterviewFeedback="openInterviewFeedback"
                @actionModalEvent="actionModalEvent"
                @openCheckList="openCheckList"
              />
            </CTab>
          </CTabs>
        </div>
      </CCardBody>
    </CCard>
    <SlotModal
      v-if="slotModal.isShowPopup"
      :slots="slotModal.slots"
      :isShowPopup="slotModal.isShowPopup"
      @modalCallBack="slotModalCallBack"
    />
    <CModal
      title="Update Expiry Date"
      centered:true
      color="primary"
      :show.sync="expiryUpdateModel.isShowPopup"
      v-if="expiryUpdateModel.isShowPopup"
      size="lg"
    >
      <CRow class="row mb-3">
        <label class="col-lg-3 text-primary">Job</label>
        <label class="col-lg-9 d-flex">
          <span>:</span>
          <div>
            <span
              class="mx-1 d-block"
              v-for="data in expiryUpdateModel.data"
              :key="data.job_id"
            >
              {{ data | getJobDisplayID }}
            </span>
          </div>
        </label>
        <label class="required col-lg-3 col-md-3">Expiry Review Date</label>
        <div class="col-lg-6 col-md-6">
          <DatePicker
            name="expiry_review_date"
            :value="expiryUpdateModel.expiry_review_date"
            @change="handleDatePickerChange"
            :showFromTmw="true"
          />
        </div>
      </CRow>
      <CRow class="row mb-3">
        <CCol md="12" v-if="expiryUpdateModel.data.length === 1">
          <CRow>
            <CCol md="6">
              <label class="text-primary">Expiry Review Date</label>
            </CCol>
            <CCol md="6">
              <label
                >: {{ expiryUpdateModel.data[0].expiry_review_date }}</label
              >
            </CCol>
          </CRow>
        </CCol>
        <CCol md="12">
          <CRow>
            <CCol md="3">
              <label class="text-primary"><b>Updated Review Date</b></label>
            </CCol>
            <CCol md="9">
              <label>: {{ expiryUpdateModel.expiry_review_date }}</label>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <button @click="onCancelClicked" class="btn btn-secondary">
            Cancel
          </button>
          <button @click="onUpdateClicked" class="btn btn-primary">
            Confirm
          </button>
        </footer>
      </template>
    </CModal>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="modalCallBack"
      :closeOnBackdrop="false"
    />
    <CModal
      centered:true
      color="primary"
      :show.sync="resubmitModel.isShowPopup"
      v-if="resubmitModel.isShowPopup"
      size="lg"
    >
      <template #header>
        <h5>{{ resubmitModel.title }}</h5>
        <button aria-label="Close" class="close" @click="onCancelClicked()">
          x
        </button>
      </template>
      <div>
        <h6 class="text-center text-primary" v-if="resubmitModel.isReinstate">
          Please confirm you are re-submitting this profile for review.
        </h6>
        <!-- <h6 class="text-primary border-top-primary pt-3">Alert</h6> -->
        <CRow
          v-if="
            resubmitModel.isReinstate ||
            resubmitModel.isReqInterview ||
            resubmitModel.isInterviewSlot
          "
        >
          <CCol lg="6">
            <CRow class="mb-3">
              <label class="required col-lg-12">{{
                resubmitModel.isReqInterview
                  ? `Scheduling Request`
                  : resubmitModel.isReinstate
                  ? `Re-Submit`
                  : `Action`
              }}</label>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <Select
                    name="action_id"
                    @input="handleSelect"
                    :value="profile.action_id"
                    :options="[]"
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                    :error="errors[0]"
                    :disabled="true"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
          <CCol lg="6" v-if="resubmitModel.isReinstate">
            <CRow class="mb-3">
              <label class="required col-lg-12">{{
                resubmitModel.isReinstate ? `Sub Status` : `Reason Code`
              }}</label>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <Select
                    name="reason_id"
                    @input="handleSelect"
                    :value="profile.reason_id"
                    :options="
                      options && options['reason_code']
                        ? options['reason_code']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
        </CRow>
        <CRow v-if="resubmitModel.isReqInterview">
          <CCol md="6">
            <h6 class="text-primary">Type</h6>
            <CRow>
              <CCol col="4">
                <label v-c-tooltip="'Screening'">
                  <input
                    name="type_of_process"
                    type="radio"
                    @input="handleRadioCustom('type_of_process', 'screening')"
                  />
                  Screening
                </label>
              </CCol>
              <CCol col="4">
                <label v-c-tooltip="'Main'">
                  <input
                    name="type_of_process"
                    type="radio"
                    @input="handleRadioCustom('type_of_process', 'main')"
                  />
                  Main
                </label>
              </CCol>
              <CCol col="4">
                <label v-c-tooltip="'Followup'">
                  <input
                    name="type_of_process"
                    type="radio"
                    @input="handleRadioCustom('type_of_process', 'follow_up')"
                  />
                  Followup
                </label>
              </CCol>
            </CRow>
            <h6 class="text-primary pt-2">Style</h6>
            <CRow>
              <CCol col="4">
                <label v-c-tooltip="'Phone'">
                  <input
                    name="interview_mode"
                    type="radio"
                    @input="handleRadioCustom('interview_mode', 'call')"
                  />
                  <i class="fas fa-phone-alt mx-2"></i>
                </label>
              </CCol>
              <CCol col="4">
                <label v-c-tooltip="'Live Meeting'">
                  <input
                    name="interview_mode"
                    type="radio"
                    @input="handleRadioCustom('interview_mode', 'video')"
                  />
                  <i class="fas fa-video mx-2 text-primary"></i>
                </label>
              </CCol>
              <CCol col="4">
                <label v-c-tooltip="'In-Person'">
                  <input
                    name="interview_mode"
                    type="radio"
                    @input="handleRadioCustom('interview_mode', 'in_person')"
                  />
                  <i class="fas fa-user-alt mx-2 text-success"></i>
                </label>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6">
            <h6 class="text-primary">Time</h6>
            <CRow>
              <CCol col="4">
                <label v-c-tooltip="'15 min'">
                  <input
                    name="alloted_time"
                    type="radio"
                    @input="handleRadioCustom('alloted_time', '_15_Mins')"
                    class="mr-2"
                  />
                  15min
                </label>
              </CCol>
              <CCol col="4">
                <label v-c-tooltip="'30 mins'">
                  <input
                    name="alloted_time"
                    type="radio"
                    @input="handleRadioCustom('alloted_time', '_30_Mins')"
                    class="mr-2"
                  />
                  30min
                </label>
              </CCol>
              <CCol col="4">
                <label v-c-tooltip="'60 min'">
                  <input
                    name="alloted_time"
                    type="radio"
                    @input="handleRadioCustom('alloted_time', '_60_Mins')"
                    class="mr-2"
                  />
                  60min
                </label>
              </CCol>
              <CCol col="4">
                <label v-c-tooltip="'90 min'">
                  <input
                    name="alloted_time"
                    type="radio"
                    @input="handleRadioCustom('alloted_time', '_90_Mins')"
                    class="mr-2"
                  />
                  90min
                </label>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div v-if="resubmitModel.isInterviewSlot">
              <h6 class="text-primary border-top-primary pt-3">
                Select Schedule
              </h6>
              <table
                class="table table-section table-striped border-bottom mb-3"
                style="table-layout: fixed"
              >
                <thead>
                  <th></th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </thead>
                <tbody>
                  <tr v-for="slot in slots" :key="slot.name">
                    <td class="form-radio">
                      <label>
                        <input
                          :id="`${slot.name}`"
                          name="slot"
                          type="radio"
                          @input="handleRadio(slot)"
                        />
                        {{ slot.name }}
                      </label>
                    </td>
                    <td>{{ slot.date | formatDate }}</td>
                    <td>{{ slot.start_time | formatTime }}</td>
                    <td>{{ slot.end_time | formatTime }}</td>
                  </tr>
                  <tr v-if="slots.length === 0">
                    <td colspan="4" class="text-center">
                      <p class="h5 text-muted">No Interview Slot Available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="border-top-primary pt-3 pb-2">
                <div
                  class="d-flex align-items-center cursor-pointer"
                  @click="showAddInterviewer = !showAddInterviewer"
                >
                  <h6 class="text-primary mb-2">Add Additional Interviewers</h6>
                  <em
                    class="fa-solid text-primary mx-1 mb-2"
                    :class="
                      showAddInterviewer ? 'fa-angle-down' : 'fa-angle-right'
                    "
                    v-c-tooltip="'Click to Show/Hide Additional Interviewers'"
                  />
                </div>
                <CCollapse v-show="showAddInterviewer">
                  <AddExtraInterviewer
                    ref="addExtraInterviewer"
                    :jobDetail="resubmitModel.data"
                  />
                </CCollapse>
              </div>
              <h6
                class="text-primary border-top-primary pt-3"
                v-if="
                  resubmitModel.data.interview_mode === 'video' ||
                  resubmitModel.data.interview_mode === 'call'
                "
              >
                Interview
              </h6>
              <CRow>
                <CCol
                  lg="6"
                  v-if="resubmitModel.data.interview_mode === 'video'"
                >
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Interview Link</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="interview_link"
                          :value="profile.interview_link"
                          label="interview_link"
                          @input="handleInput"
                          :error="errors[0]"
                        ></TextInput>
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  lg="6"
                  v-if="resubmitModel.data.interview_mode === 'call'"
                >
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Phone Number</label>
                    <div class="col-lg-12">
                      <ValidationProvider
                        ref="phone"
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        }"
                      >
                        <PhoneInputwithCountry
                          name="contact_phone"
                          :value="profile.contact_phone"
                          :options="
                            options && options['dialingCode']
                              ? options['dialingCode']
                              : []
                          "
                          @change="handleInput"
                          :error="errors[0]"
                          ref="phoneNo"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
        <!-- <CRow v-if="resubmitModel.isReinstate || resubmitModel.isReqInterview || resubmitModel.isInterviewSlot"> -->
        <!-- Documnets for Candidate Offer -->
        <CRow
          class="row mb-3"
          v-if="
            !resubmitModel.isInterviewSlot &&
            !resubmitModel.isReinstate &&
            !resubmitModel.isReqInterview &&
            !resubmitModel.isOnboardDocument &&
            !resubmitModel.isViewDocs &&
            !resubmitModel.isVault &&
            !resubmitModel.isResubmit &&
            !resubmitModel.isjobreapproval
          "
        >
          <div class="p-3" style="width: 100%">
            <CTabs
              variant="tabs"
              :active-tab.sync="currentTabfordocument"
              @update:activeTab="onTabChangefordocuments"
            >
              <CTab
                :title="`Job (${
                  jobDocuments.length ? jobDocuments.length : 0
                })`"
                @click.native="onJobTab = true"
              >
                <CRow class="m-2">
                  <CCol class="float-right">
                    <CButton
                      v-if="jobDocuments && jobDocuments.length && !showSendAll"
                      color="primary"
                      class="m-2 f-12"
                      size="sm"
                      style="float: right"
                      @click="sendAllDoc"
                    >
                      Send All
                    </CButton>
                  </CCol>
                </CRow>
                <CRow
                  class="
                    ml-2
                    mr-2
                    mt-4
                    d-flex
                    align-items-center
                    documentScrolling
                    document-table
                  "
                  v-if="jobDocuments.length"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="w-25">Document Type</th>
                        <th>Document Name</th>
                        <th>Actionable</th>
                        <th>Uploaded Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in jobDocuments"
                        :key="index"
                        :style="`${
                          selected_job_docs.includes(
                            data.organisation_document_id
                          )
                            ? 'background-color: #D0F0C0'
                            : 'background-color: white'
                        }`"
                      >
                        <td :class="{ 'w-25': true, [data.label]: true }">
                          {{ data.label }}
                        </td>
                        <td class="candidate-file-name">
                          {{ data.name || "--" }}
                        </td>
                        <td>
                          {{ data.sign_req ? "Yes" : "No" }}
                          <!-- <input
                            type="checkbox"
                            :name="`actionable-checkbox-${index}`"
                            :value="data.organisation_document_id"
                            :checked="data.sign_req || false"
                            :disabled="checkDocExist(data)"
                            @click="handleJobCheck(data, $event)"
                              class="mb-2 ml-4"
                          /> -->
                        </td>
                        <td>{{ data.updatedDate }}</td>
                        <td v-if="!checkDocExist(data)">
                          <!-- <input
                            type="checkbox"
                            :name="`actionable-checkbox-${index}`"
                            :value="data.organisation_document_id"
                            v-model="selected_job_docs"
                            @click="handleSendCheck(data, $event)"
                            class="btn icon hand-icon mb-1 ml-3"
                          /> -->
                          <CButton
                            v-if="!checkDocExist(data)"
                            color="primary"
                            :id="`${data.job_document_id}`"
                            :ref="`${data.job_document_id}`"
                            :name="`${data.job_document_id}`"
                            class="pd-2 f-12"
                            size="sm"
                            @click="sendOrRemoveDoc(data, 'Add')"
                          >
                            Send
                          </CButton>
                          <!-- <CButton
                            v-else
                            color="primary"
                            class="px-2 f-12"
                            size="sm"
                            @click="sendOrRemoveDoc(data, 'Remove')"
                          >
                            Remove
                          </CButton> -->
                        </td>
                        <td v-else></td>
                      </tr>
                    </tbody>
                  </table>
                </CRow>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </CTab>
              <CTab
                :title="`Contract/Legal  (${candidateDocument.length})`"
                @click.native="onJobTab = false"
              >
                <CCardGroup class="has-loading-overlay">
                  <CCard class="p-2">
                    <CCardBody>
                      <ValidationObserver v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(onSubmit)">
                          <CRow class="row mb-3">
                            <label class="col-lg-3 col-md-12"
                              >Document Type</label
                            >
                            <Select
                              class="col-lg-6 col-md-6"
                              name="document_type"
                              :value="uploadData.document_type || `--Select--`"
                              :options="
                                options && options['document_type']
                                  ? options['document_type']
                                  : []
                              "
                              @input="handleChangeSelectType"
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                            />
                          </CRow>
                          <CRow class="row mb-3" v-if="addbtnshow">
                            <label class="col-lg-3 col-md-12"
                              >Add Document</label
                            >
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <CButton
                                color="primary"
                                class="px-2 f-12"
                                @click="adddocument"
                              >
                                ADD
                              </CButton>
                            </div>
                          </CRow>
                          <CRow class="row mb-3" v-if="custombtn">
                            <label class="col-lg-3 col-md-12"
                              >Document Name</label
                            >
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <!--  <Select
                              name="document_type"
                              :value="uploadData.document_type || `Terms & Conditions`"
                              @input="handleChangeSelect_fordocument"
                              :options="
                                options && options['document_type']
                                  ? options['document_type']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                            /> -->
                              <ValidationObserver ref="DocumentInfo" tag="form">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    name="comments_dtype"
                                    ref="comments_dtype"
                                    :value="uploadData.comments_dtype"
                                    @input="handleInput_offer"
                                    :error="errors[0]"
                                  />
                                </ValidationProvider>
                              </ValidationObserver>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <input
                                name="upload-document"
                                type="file"
                                ref="fileref"
                                :accept="arraytoComma(fileType)"
                                @change="selectedFile"
                                @click="OnClickDocument($event)"
                              />
                            </div>
                            <div v-if="isImageSelected">
                              <uploadDocument
                                :documentType="uploadData.document_type"
                                :isProfilePic="false"
                                :fileData="fileData"
                                @closeModal="closeModal"
                                v-on="$listeners"
                                @upload="upload"
                              />
                            </div>
                            <!-- <div v-if="cropperModal.isShowPopup">
                            <CropperModal
                              :isShowPopup="cropperModal.isShowPopup"
                              :fileData="cropperModal.fileData"
                              modalColor="primary"
                              :buttonLabel="cropperModal.buttonLabel"
                              @modalCallBack="cropperModalCallBack"
                              :aspectRatio="2"
                            />
                          </div> -->
                          </CRow>
                        </form>
                      </ValidationObserver>
                    </CCardBody>
                    <CRow
                      class="
                        ml-2
                        mr-2
                        d-flex
                        align-items-center
                        documentScrolling
                        document-table
                      "
                      v-if="candidateDocument.length"
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="w-25">Type</th>
                            <th>Name</th>
                            <th>Uploaded Date</th>
                            <th>Actionable</th>
                            <!-- <th>Download</th> -->
                            <th>Preview</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in candidateDocument"
                            :key="index"
                          >
                            <td :class="{ 'w-25': true, [data.label]: true }">
                              {{ data.label }}
                            </td>
                            <td class="candidate-file-name">
                              {{ data.name || "--" }}
                            </td>
                            <td>{{ data.updatedDate }}</td>
                            <td>
                              <input
                                type="checkbox"
                                :name="`actionable-checkbox-${index}`"
                                :value="data.document_id"
                                :checked="data.actionable || false"
                                @click="handleCheck(data, $event)"
                                class="mb-2 ml-4"
                              />
                            </td>
                            <!-- <td>
                              <button
                                type="button"
                                :disabled="disabledButton.includes(index)"
                                class="btn icon hand-icon mb-1 ml-2"
                                v-if="data && data.link"
                                @click="fileDownload(data, index)"
                              >
                                <i class="icon fas fa-download"></i>
                              </button>
                              <span v-else>--</span>
                            </td> -->
                            <td>
                              <a
                                class="btn icon hand-icon mb-1"
                                v-if="data && data.link"
                                @click="preview(data)"
                              >
                                <i class="icon fas fa-eye ml-1"></i>
                              </a>
                              <span v-else>--</span>
                            </td>
                            <td>
                              <button
                                class="btn icon hand-icon mb-1"
                                v-if="data"
                                @click="deleteDocuments(data)"
                              >
                                <i class="icon fas fa-trash ml-1"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CRow>
                  </CCard>
                </CCardGroup>
              </CTab>
            </CTabs>
          </div>
        </CRow>
        <!-- Documnets for Candidate Onboard -->
        <CRow
          class="row mb-3"
          v-if="
            !resubmitModel.isInterviewSlot &&
            !resubmitModel.isReinstate &&
            !resubmitModel.isReqInterview &&
            !resubmitModel.isOfferedstate &&
            !resubmitModel.isViewDocs &&
            !resubmitModel.isOfferDocument &&
            !resubmitModel.isVault &&
            !resubmitModel.isResubmit &&
            !resubmitModel.isjobreapproval
          "
        >
          <div class="p-3" style="width: 100%">
            <CTabs
              variant="tabs"
              :active-tab.sync="currentTabfordocument"
              @update:activeTab="onTabChangefordocuments"
            >
              <CTab
                :title="`Return Documents (${
                  candidateDocument_offerLetter
                    ? candidateDocument_offerLetter.length
                    : 0
                })`"
              >
                <div
                  v-if="
                    candidateDocument_offerLetter &&
                    candidateDocument_offerLetter.length
                  "
                  class="multi-records"
                >
                  <div
                    class="top-border d-flex"
                    style="justify-content: space-between"
                  >
                    <div class="ml-2 mt-1 record" style="min-width: 80%">
                      <CRow
                        v-for="(data, index) in candidateDocument_offerLetter"
                        :key="index"
                        class="d-flex"
                        style="justify-content: space-around; margin-left: 2px"
                      >
                        <div class="table col col-3">
                          <p class="head">Document Type</p>
                          <p class="data">{{ data.label }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Document Name</p>
                          <p class="data">{{ data.name }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Uploaded Date</p>
                          <p class="data">{{ data.updatedDate }}</p>
                        </div>
                        <!-- <div class="text-justify table col col-12">
                        <p class="head">Description</p>
                        <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                          <i class="fas fa-pencil-alt"></i>
                        </a>
                        {{ data.comments ? data.comments : "--" }}
                      </div> -->

                        <div
                          class="
                            widget-content-actions
                            d-flex
                            flex-column
                            justify-content-center
                          "
                          style="margin-right: 5px"
                        >
                          <button
                            type="button"
                            :disabled="disabledButton.includes(index)"
                            class="btn icon hand-icon mb-1"
                            v-if="data && data.link"
                            @click="fileDownload(data, index)"
                          >
                            <i class="icon fas fa-download"></i>
                          </button>

                          <button
                            type="button"
                            class="btn icon hand-icon mb-1"
                            v-if="data && data.link"
                            @click="preview(data)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>

                          <!-- <button
                      class="btn icon hand-icon"
                      v-if="data && data.link"
                      @click="deleteDocuments(data)"
                    >
                      <i class="fas fa-trash"></i>
                    </button> -->
                        </div>
                      </CRow>
                    </div>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </CTab>
              <CTab
                :title="`Pending Documents (${
                  candidatePendingOfferDocuments
                    ? candidatePendingOfferDocuments.length
                    : 0
                })`"
              >
                <div
                  v-if="
                    candidatePendingOfferDocuments &&
                    candidatePendingOfferDocuments.length
                  "
                  class="multi-records"
                >
                  <div class="ml-2 mt-1 record" style="min-width: 80%">
                    <CRow
                      v-for="(data, index) in candidatePendingOfferDocuments"
                      :key="index"
                      class="d-flex"
                      style="justify-content: space-around; margin-left: 2px"
                    >
                      <div class="table col col-3">
                        <p class="head">Document Type</p>
                        <p class="data">{{ data.label || "--" }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Document Name</p>
                        <p class="data">{{ data.name || "--" }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Uploaded Date</p>
                        <p class="data">{{ data.updatedDate || "--" }}</p>
                      </div>
                    </CRow>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  No Pending Docs
                </p>
              </CTab>
              <CTab
                :title="`Onboarding Documents (${candidateDocumentforonboard.length})`"
              >
                <CCard class="p-2">
                  <CCardBody>
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form @submit.prevent="handleSubmit(onSubmit)">
                        <CRow class="row mb-3">
                          <label class="col-lg-3 col-md-12"
                            >Document Type</label
                          >
                          <Select
                            class="col-lg-6 col-md-6"
                            name="document_type"
                            :value="uploadData.document_type || `--Select--`"
                            @input="handleChangeSelectType"
                            :options="
                              options && options['document_type']
                                ? options['document_type']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                          />
                          <!--  <div class="col-lg-4 col-md-6 col-sm-12">
                          <ValidationObserver ref="DocumentInfo" tag="form">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                             <TextInput
                  name="comments_dtype"
                  ref="comments_dtype"
                  :value="uploadData.comments_dtype"
                  @input="handleInput_offer"
                   :error="errors[0]"
                />
                </ValidationProvider>
                </ValidationObserver>
                
                          </div>-->
                        </CRow>
                        <CRow class="row mb-3" v-if="addbtnshow">
                          <label class="col-lg-3 col-md-12">Add Document</label>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <CButton
                              color="primary"
                              class="px-2 f-12"
                              @click="adddocument"
                            >
                              ADD
                            </CButton>
                          </div>
                          <!-- <div v-if="cropperModal.isShowPopup">
                            <CropperModal
                              :isShowPopup="cropperModal.isShowPopup"
                              :fileData="cropperModal.fileData"
                              modalColor="primary"
                              :buttonLabel="cropperModal.buttonLabel"
                              @modalCallBack="cropperModalCallBack"
                              :aspectRatio="2"
                            />
                          </div> -->
                        </CRow>
                        <CRow class="row mb-3" v-if="custombtn">
                          <label class="col-lg-3 col-md-12 required"
                            >Upload Document</label
                          >
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <ValidationObserver ref="DocumentInfo" tag="form">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  name="comments_dtype"
                                  ref="comments_dtype"
                                  :value="uploadData.comments_dtype"
                                  @input="handleInput_offer"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </ValidationObserver>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <input
                              name="upload-document"
                              type="file"
                              ref="fileref"
                              :accept="arraytoComma(fileType)"
                              @change="selectedFile"
                              @click="OnClickDocument($event)"
                            />
                          </div>
                          <div v-if="isImageSelected">
                            <uploadDocument
                              :documentType="uploadData.document_type"
                              :isProfilePic="false"
                              :fileData="fileData"
                              @closeModal="closeModal"
                              v-on="$listeners"
                              @upload="upload"
                            />
                          </div>
                          <!-- <div v-if="cropperModal.isShowPopup">
                            <CropperModal
                              :isShowPopup="cropperModal.isShowPopup"
                              :fileData="cropperModal.fileData"
                              modalColor="primary"
                              :buttonLabel="cropperModal.buttonLabel"
                              @modalCallBack="cropperModalCallBack"
                              :aspectRatio="2"
                            />
                          </div> -->
                        </CRow>
                      </form>
                    </ValidationObserver>
                  </CCardBody>
                  <CRow
                    class="
                      ml-2
                      mr-2
                      d-flex
                      align-items-center
                      documentScrolling
                      document-table
                    "
                    v-if="candidateDocumentforonboard"
                  >
                    <table
                      class="table"
                      v-if="candidateDocumentforonboard.length"
                    >
                      <thead>
                        <tr>
                          <th class="w-25">Document Type</th>
                          <th>Document Name</th>
                          <th>Uploaded Date</th>
                          <th>Actionable</th>
                          <th>Download</th>
                          <th>Preview</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in candidateDocumentforonboard"
                          :key="index"
                        >
                          <td :class="{ 'w-25': false, [data.label]: true }">
                            {{ data.label }}
                          </td>
                          <td class="candidate-file-name">
                            {{ data.name || "--" }}
                          </td>
                          <td>{{ data.updatedDate }}</td>
                          <td>
                            <input
                              type="checkbox"
                              :name="`actionable-checkbox-${index}`"
                              :value="data.document_id"
                              :checked="data.actionable || false"
                              @click="handleCheck(data, $event)"
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              :disabled="disabledButton.includes(index)"
                              class="btn icon hand-icon mb-1"
                              v-if="data && data.link"
                              @click="fileDownload(data, index)"
                            >
                              <i class="icon fas fa-download"></i>
                            </button>
                            <span v-else>--</span>
                          </td>
                          <td>
                            <a
                              class="btn icon hand-icon mb-1"
                              v-if="data && data.link"
                              @click="preview(data)"
                            >
                              <i class="icon fas fa-eye ml-3"></i>
                            </a>
                            <span v-else>--</span>
                          </td>
                          <td>
                            <button
                              class="btn icon hand-icon mb-1"
                              v-if="data"
                              @click="deleteDocuments(data)"
                            >
                              <i class="icon fas fa-trash ml-3"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CRow>
                </CCard>
                <!--   <div v-if="orgDocuments.length" class="multi-records">
                  <div
                    class="top-border d-flex"
                    style="justify-content: space-between"
                    v-for="(data, index) in orgDocuments"
                    :key="index"
                  >
                   <div class="ml-2 mt-1 record" style="min-width: 80%">
                      <CRow
                        class="d-flex"
                        style="justify-content: space-around; margin-left: 2px"
                      >
                        <label style="display: flex; align-items: center">
                          <input type="checkbox" :value="data.document_id" />
                        </label>
                        <div class="table col col-4">
                          <p class="head">Document Type</p>
                          <p class="data">{{ data.comments }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Document Name</p>
                          <p class="data">{{ data.name }}</p>
                        </div>
                        <div class="table col col-2">
                          <p class="head">Uploaded Date</p>
                          <p class="data">{{ data.updatedDate }}</p>
                        </div>
                         <div class="text-justify table col col-12">
                        <p class="head">Description</p>
                        <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                          <i class="fas fa-pencil-alt"></i>
                        </a>
                        {{ data.comments ? data.comments : "--" }}
                      </div> 
                      </CRow>
                    </div>

                    <div
                      class="
                        widget-content-actions
                        d-flex
                        flex-column
                        justify-content-center
                      "
                      style="margin-right: 5px"
                    >
                      <button
                        type="button"
                        :disabled="disabledButton.includes(index)"
                        class="btn icon hand-icon mb-1"
                        v-if="data && data.link"
                        @click="fileDownload(data, index)"
                      >
                        <i class="icon fas fa-download"></i>
                      </button>

                      <button
                        type="button"
                        class="btn icon hand-icon mb-1"
                        v-if="data && data.link"
                        @click="preview(data)"
                      >
                        <i class="fas fa-eye"></i>
                      </button>

                      <button
                      class="btn icon hand-icon"
                      v-if="data && data.link"
                      @click="deleteDocuments(data)"
                    >
                      <i class="fas fa-trash"></i>
                    </button> 
                    </div>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p> -->
              </CTab>
            </CTabs>
          </div>
        </CRow>
        <!-- Documnets for Candidate Uploaded -->
        <CRow
          class="row mb-3"
          v-if="
            !resubmitModel.isInterviewSlot &&
            !resubmitModel.isReinstate &&
            !resubmitModel.isReqInterview &&
            !resubmitModel.isOfferedstate &&
            !resubmitModel.isOnboardDocument &&
            !resubmitModel.isOfferDocument &&
            !resubmitModel.isVault &&
            !resubmitModel.isResubmit &&
            !resubmitModel.isjobreapproval
          "
        >
          <div class="p-3" style="width: 100%">
            <CTabs
              variant="tabs"
              :active-tab.sync="currentTabfordocument"
              @update:activeTab="onTabChangefordocuments"
            >
              <CTab
                :title="`Offer (${
                  candidateDocument_offerLetter
                    ? candidateDocument_offerLetter.length
                    : 0
                })`"
              >
                <div
                  v-if="
                    candidateDocument_offerLetter &&
                    candidateDocument_offerLetter.length
                  "
                  class="multi-records"
                >
                  <div
                    class="top-border d-flex"
                    style="justify-content: space-between"
                    v-for="(data, index) in candidateDocument_offerLetter"
                    :key="index"
                  >
                    <div class="ml-2 mt-1 record" style="min-width: 80%">
                      <CRow
                        class="d-flex"
                        style="justify-content: space-around; margin-left: 2px"
                      >
                        <div class="table col col-3">
                          <p class="head">Document Type</p>
                          <p class="data">{{ data.label }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Document Name</p>
                          <p class="data">{{ data.name }}</p>
                        </div>
                        <div class="table col col-3">
                          <p class="head">Uploaded Date</p>
                          <p class="data">{{ data.updatedDate }}</p>
                        </div>
                        <!-- <div class="text-justify table col col-12">
                        <p class="head">Description</p>
                        <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                          <i class="fas fa-pencil-alt"></i>
                        </a>
                        {{ data.comments ? data.comments : "--" }}
                      </div> -->
                        <div
                          class="
                            widget-content-actions
                            d-flex
                            flex-column
                            justify-content-center
                          "
                          style="margin-right: 5px"
                        >
                          <button
                            type="button"
                            :disabled="disabledButton.includes(0)"
                            class="btn icon hand-icon mb-1"
                            v-if="
                              getLatestVersionForCandidateOffer &&
                              getLatestVersionForCandidateOffer.link
                            "
                            @click="
                              fileDownload(getLatestVersionForCandidateOffer, 0)
                            "
                          >
                            <i class="icon fas fa-download"></i>
                          </button>
                          <button
                            type="button"
                            class="btn icon hand-icon mb-1"
                            v-if="
                              getLatestVersionForCandidateOffer &&
                              getLatestVersionForCandidateOffer.link
                            "
                            @click="preview(getLatestVersionForCandidateOffer)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </CRow>
                    </div>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </CTab>
              <CTab
                :title="`OnBoard  (${
                  candidateDocumentAfterUplaod
                    ? candidateDocumentAfterUplaod.length
                    : 0
                })`"
              >
                <div
                  v-if="candidateDocumentAfterUplaod.length"
                  class="multi-records"
                >
                  <div
                    class="top-border d-flex"
                    style="justify-content: space-between"
                    v-for="(data, index) in candidateDocumentAfterUplaod"
                    :key="index"
                  >
                    <div class="ml-2 mt-1 record" style="min-width: 80%">
                      <CRow
                        class="d-flex"
                        style="justify-content: space-around; margin-left: 2px"
                      >
                        <div class="table col col-4">
                          <p class="head">Document Type</p>
                          <p class="data">{{ data.label }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Document Name</p>
                          <p class="data">{{ data.name }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Uploaded Date</p>
                          <p class="data">{{ data.updatedDate }}</p>
                        </div>
                        <!--<div class="text-justify table col col-12">
                        <p class="head">Description</p>
                        <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                          <i class="fas fa-pencil-alt"></i>
                        </a>
                        {{ data.comments ? data.comments : "--" }}
                      </div> -->
                      </CRow>
                    </div>

                    <div
                      class="
                        widget-content-actions
                        d-flex
                        flex-column
                        justify-content-center
                      "
                      style="margin-right: 5px"
                    >
                      <button
                        type="button"
                        :disabled="disabledButton.includes(index)"
                        class="btn icon hand-icon mb-1"
                        v-if="data && data.link"
                        @click="fileDownload(data, index)"
                      >
                        <i class="icon fas fa-download"></i>
                      </button>

                      <button
                        type="button"
                        class="btn icon hand-icon mb-1"
                        v-if="data && data.link"
                        @click="preview(data)"
                      >
                        <i class="fas fa-eye"></i>
                      </button>

                      <!-- <button
                      class="btn icon hand-icon"
                      v-if="data && data.link"
                      @click="deleteDocuments(data)"
                    >
                      <i class="fas fa-trash"></i>
                    </button> -->
                    </div>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </CTab>
            </CTabs>
          </div>
        </CRow>
        <CRow
          v-if="
            !resubmitModel.isReinstate &&
            !resubmitModel.isViewDocs &&
            !resubmitModel.isVault &&
            !resubmitModel.isjobreapproval &&
            !onJobTab
          "
        >
          <CCol lg="12">
            <h6 class="border-top-primary pt-2"></h6>
          </CCol>
          <CCol lg="12">
            <CRow class="mb-3">
              <CCol lg="6" class="px-0">
                <label class="col-lg-12">Candidate Joining Date</label>
                <div class="col-lg-12">
                  <DatePicker
                    name="joining_date"
                    :value="profile.joining_date"
                    @input="handleDateChange"
                    format="DD-MM-YYYY"
                    :showFromToday="true"
                  />
                </div>
              </CCol>
            </CRow>
          </CCol>
          <CCol lg="6">
            <CRow class="mb-3">
              <label class="col-lg-12 required">Alert Set For</label>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <RadioButton
                    name="alert_days"
                    :value="profile.alert_days"
                    :options="
                      options && options['alert_days']
                        ? options['alert_days']
                        : []
                    "
                    @change="handleChangeRadio"
                  />
                  <DatePicker
                    v-if="payload.alert_days && payload.alert_days === 'custom'"
                    name="expiry_date"
                    :value="profile.expiry_date"
                    @input="handleDateChange"
                    :error="errors[0]"
                    format="DD-MM-YYYY"
                    :showFromToday="true"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>

          <CCol lg="6">
            <CRow class="mb-3">
              <label name="lbl_urgent" class="col-lg-12 col-md-12"
                >Is Urgent?</label
              >
              <CCol md="12">
                <RadioButton
                  name="is_urgent"
                  :value="profile.is_urgent"
                  :options="
                    options && options['boolean'] ? options['boolean'] : []
                  "
                  @change="handleChangeRadio"
                />
              </CCol>
            </CRow>
          </CCol>
          <!-- <CCol lg="6">
            <CRow class="mb-3">
              <label class="col-lg-12 required">Priority Level</label>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <Select
                    name="priority_level"
                    @input="handleSelect"
                    :value="profile.priority_level"
                    :options="
                      options && options['priority_level']
                        ? options['priority_level']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol> -->
        </CRow>
        <CRow class="mb-3">
          <CCol lg="8">
            <!---------------------------------------------------------------- DocumentsModel -->
            <CRow
              class="mb-3"
              v-if="
                !resubmitModel.isViewDocs &&
                !resubmitModel.isVault &&
                !resubmitModel.isjobreapproval &&
                !onJobTab
              "
            >
              <div class="col-lg-12 col-md-12 d-flex pb-2">
                <div>
                  <label class="text-nowrap pr-3 required">Comments</label>
                </div>
                <div class="w-100" v-if="options['comments_options'].length">
                  <Select
                    name="comments_options"
                    @input="handlecommentsforrecruiter"
                    :value="commentsData.comments_options"
                    :options="
                      options && options['comments_options']
                        ? options['comments_options']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextAreaInput
                    ref="comments"
                    name="comments"
                    :value="profile.comments"
                    label="comments"
                    @input="handleInput"
                    :error="errors[0]"
                  ></TextAreaInput>
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <!---------------------------------------------------------------- DocumentsModel -->
            <CRow
              class="mb-3"
              v-if="
                !resubmitModel.isReinstate &&
                !resubmitModel.isViewDocs &&
                !resubmitModel.isReqInterview &&
                !resubmitModel.isInterviewSlot &&
                !resubmitModel.isOfferedstate &&
                !resubmitModel.isOfferDocument &&
                !resubmitModel.isOnboardDocument &&
                !resubmitModel.isViewDocs &&
                !resubmitModel.isResubmit &&
                !resubmitModel.isjobreapproval
              "
            >
              <label class="col-lg-12 required"
                >Are you Sure want to Move the Candidate to Vault</label
              >
            </CRow>
          </CCol>
        </CRow>

        <!---------------------------------------------------------------- DocumentsModel -->
        <PreviewModal
          :modalTitle="confirmationModal.modalTitle"
          :modalColor="confirmationModal.modalColor"
          :isShowPopup="confirmationModal.isShowPopup"
          :buttons="confirmationModal.buttons"
          :modalCallBack="modalCallBack_preview"
          :isPreview="confirmationModal.isPreview"
          :iframe="iframe"
          :size="confirmationModal.size"
          :closeOnBackdrop="confirmationModal.closeOnBackdrop"
        />
        <Modal
          :modalTitle="deleteConfirmModal.modalTitle"
          :modalColor="deleteConfirmModal.modalColor"
          :modalContent="deleteConfirmModal.modalContent"
          :isShowPopup="deleteConfirmModal.isShowPopup"
          :buttons="deleteConfirmModal.buttons"
          :modalCallBack="deletemodalCallBack_document"
          :closeOnBackdrop="false"
        />
        <Modal
          :modalTitle="offerConfirmModal.modalTitle"
          :modalColor="offerConfirmModal.modalColor"
          :modalContent="offerConfirmModal.modalContent"
          :isShowPopup="offerConfirmModal.isShowPopup"
          :buttons="offerConfirmModal.buttons"
          :modalCallBack="offerConfirmModalCallBack"
          :closeOnBackdrop="false"
        />

        <Modal
          :modalTitle="offerDocumentConfirmModal.modalTitle"
          :modalColor="offerDocumentConfirmModal.modalColor"
          :modalContent="offerDocumentConfirmModal.modalContent"
          :isShowPopup="offerDocumentConfirmModal.isShowPopup"
          :buttons="offerDocumentConfirmModal.buttons"
          :modalCallBack="offerDocumentConfirmModalCallBack"
          :closeOnBackdrop="false"
        />
        <Modal
          :modalTitle="onboardConfirmModal.modalTitle"
          :modalColor="onboardConfirmModal.modalColor"
          :modalContent="onboardConfirmModal.modalContent"
          :isShowPopup="onboardConfirmModal.isShowPopup"
          :buttons="onboardConfirmModal.buttons"
          :modalCallBack="onboardConfirmModalCallBack"
          :closeOnBackdrop="false"
        />
        <Modal
          :modalTitle="onVaultconfirmModal.modalTitle"
          :modalColor="onVaultconfirmModal.modalColor"
          :modalContent="onVaultconfirmModal.modalContent"
          :isShowPopup="onVaultconfirmModal.isShowPopup"
          :buttons="onVaultconfirmModal.buttons"
          :modalCallBack="onVaultconfirmModalmodalCallBack"
          :closeOnBackdrop="false"
        />

        <!----------------------------------------------------------------------------->
      </div>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <button @click="onCancelClicked" class="btn btn-secondary">
            Cancel
          </button>
          <button
            @click="onJobreapprovalClicked"
            v-if="resubmitModel.isjobreapproval"
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onResubmitClicked"
            v-if="resubmitModel.isResubmit && !resubmitModel.isVault"
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onReinstateSubmit"
            v-if="resubmitModel.isReinstate && !resubmitModel.isVault"
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onRequestInterviewSubmit"
            v-if="resubmitModel.isReqInterview && !resubmitModel.isVault"
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onInterviewSlotSubmit"
            v-if="resubmitModel.isInterviewSlot && !resubmitModel.isVault"
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onOfferedSubmit"
            v-if="
              resubmitModel.isOfferedstate &&
              !resubmitModel.isInterviewSlot &&
              showbtn &&
              !resubmitModel.isVault
            "
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onDocumentSubmit"
            v-if="
              resubmitModel.isOfferDocument &&
              !resubmitModel.isOfferedstate &&
              !resubmitModel.isInterviewSlot &&
              showbtn &&
              !resubmitModel.isVault
            "
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onOnboardSubmit"
            v-if="
              !resubmitModel.isOfferedstate &&
              !resubmitModel.isInterviewSlot &&
              resubmitModel.isOnboardDocument &&
              !resubmitModel.isVault
            "
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            @click="onVaultSubmit"
            v-if="
              !resubmitModel.isOfferedstate &&
              !resubmitModel.isInterviewSlot &&
              !resubmitModel.isOnboardDocument &&
              resubmitModel.isVault &&
              !resubmitModel.isReinstate
            "
            class="btn btn-primary"
          >
            Yes
          </button>
        </footer>
      </template>
    </CModal>
    <CModal
      id="documentShowModal"
      color="primary"
      :show.sync="documentShowModal.isShowPopup"
      size="lg"
    >
      <template #header>
        <h5>Document Details</h5>
        <button
          aria-label="Close"
          class="close"
          @click="documentShowModal.isShowPopup = false"
        >
          x
        </button>
      </template>
      <div style="width: 100%">
        <CTabs variant="tabs" :active-tab="activeTabForDoc" class="mt-3">
          <CTab :title="`Offer`">
            <CTabs variant="tabs" :active-tab="activeOfferDocTab" class="p-2">
              <CTab
                :title="`Return Documents (${
                  candidateUploadedOfferDocuments
                    ? candidateUploadedOfferDocuments.length
                    : 0
                })`"
              >
                <div
                  v-if="
                    candidateUploadedOfferDocuments &&
                    candidateUploadedOfferDocuments.length
                  "
                  class="multi-records"
                >
                  <div
                    class="top-border d-flex"
                    style="justify-content: space-between"
                  >
                    <div class="ml-2 mt-1 record" style="min-width: 80%">
                      <CRow
                        v-for="(data, index) in candidateDocument_offerLetter"
                        :key="index"
                        class="d-flex"
                        style="justify-content: space-around; margin-left: 2px"
                      >
                        <div class="table col col-3">
                          <p class="head">Document Type</p>
                          <p class="data">{{ data.label }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Document Name</p>
                          <p class="data">{{ data.name || "--" }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Uploaded Date</p>
                          <p class="data">{{ data.updatedDate || "--" }}</p>
                        </div>
                        <div
                          class="
                            widget-content-actions
                            d-flex
                            flex-column
                            justify-content-center
                          "
                          style="margin-right: 5px"
                        >
                          <button
                            type="button"
                            :disabled="disabledButton.includes(index)"
                            class="btn icon hand-icon mb-1"
                            v-if="data && data.link"
                            @click="fileDownload(data, index)"
                          >
                            <i class="icon fas fa-download"></i>
                          </button>

                          <button
                            type="button"
                            class="btn icon hand-icon mb-1"
                            v-if="data && data.link"
                            @click="preview(data)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </CRow>
                    </div>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </CTab>
              <CTab
                :title="`Pending Documents (${
                  candidatePendingOfferDocuments
                    ? candidatePendingOfferDocuments.length
                    : 0
                })`"
              >
                <div
                  v-if="
                    candidatePendingOfferDocuments &&
                    candidatePendingOfferDocuments.length
                  "
                  class="multi-records"
                >
                  <div class="ml-2 mt-1 record" style="min-width: 80%">
                    <CRow
                      v-for="(data, index) in candidatePendingOfferDocuments"
                      :key="index"
                      class="d-flex"
                      style="justify-content: space-around; margin-left: 2px"
                    >
                      <div class="table col col-3">
                        <p class="head">Document Type</p>
                        <p class="data">{{ data.label || "--" }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Document Name</p>
                        <p class="data">{{ data.name || "--" }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Uploaded Date</p>
                        <p class="data">{{ data.updatedDate || "--" }}</p>
                      </div>
                    </CRow>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  No Pending Docs
                </p>
              </CTab>
            </CTabs>
          </CTab>
          <CTab v-if="!displayTab.offered" :title="`Onboard`">
            <CTabs variant="tabs" :active-tab="activeOnboardDocTab" class="p-2">
              <CTab
                :title="`Return Documents (${
                  candidateUploadedOnboardDocuments
                    ? candidateUploadedOnboardDocuments.length
                    : 0
                })`"
              >
                <div
                  v-if="
                    candidateUploadedOnboardDocuments &&
                    candidateUploadedOnboardDocuments.length
                  "
                  class="multi-records"
                >
                  <div
                    class="top-border d-flex"
                    style="justify-content: space-between"
                  >
                    <div class="ml-2 mt-1 record" style="min-width: 80%">
                      <CRow
                        v-for="(
                          data, index
                        ) in candidateUploadedOnboardDocuments"
                        :key="index"
                        class="d-flex"
                        style="justify-content: space-around; margin-left: 2px"
                      >
                        <div class="table col col-3">
                          <p class="head">Document Type</p>
                          <p class="data">{{ data.label }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Document Name</p>
                          <p class="data">{{ data.name || "--" }}</p>
                        </div>
                        <div class="table col col-4">
                          <p class="head">Uploaded Date</p>
                          <p class="data">{{ data.updatedDate || "--" }}</p>
                        </div>
                        <div
                          class="
                            widget-content-actions
                            d-flex
                            flex-column
                            justify-content-center
                          "
                          style="margin-right: 5px"
                        >
                          <button
                            type="button"
                            :disabled="disabledButton.includes(index)"
                            class="btn icon hand-icon mb-1"
                            v-if="data && data.link"
                            @click="fileDownload(data, index)"
                          >
                            <i class="icon fas fa-download"></i>
                          </button>

                          <button
                            type="button"
                            class="btn icon hand-icon mb-1"
                            v-if="data && data.link"
                            @click="preview(data)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </CRow>
                    </div>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  Not Yet Uploaded
                </p>
              </CTab>
              <CTab
                :title="`Pending Documents (${
                  candidatePendingOnboardDocuments
                    ? candidatePendingOnboardDocuments.length
                    : 0
                })`"
              >
                <div
                  v-if="
                    candidatePendingOnboardDocuments &&
                    candidatePendingOnboardDocuments.length
                  "
                  class="multi-records"
                >
                  <div class="ml-2 mt-1 record" style="min-width: 80%">
                    <CRow
                      v-for="(data, index) in candidatePendingOnboardDocuments"
                      :key="index"
                      class="d-flex"
                      style="justify-content: space-around; margin-left: 2px"
                    >
                      <div class="table col col-3">
                        <p class="head">Document Type</p>
                        <p class="data">{{ data.label || "--" }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Document Name</p>
                        <p class="data">{{ data.name || "--" }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Uploaded Date</p>
                        <p class="data">{{ data.updatedDate || "--" }}</p>
                      </div>
                    </CRow>
                  </div>
                </div>
                <p v-else class="text-muted h5 text-center p-4">
                  No Pending Docs
                </p>
              </CTab>
            </CTabs>
          </CTab>
        </CTabs>
      </div>
      <template #footer>
        <div>
          <CButton class="ml-2" color="primary" @click="closeDocumentModal()"
            >Close</CButton
          >
        </div>
      </template>
    </CModal>
    <!-- <Modal
      :modalTitle="joiningConfirmModal.modalTitle"
      :modalColor="joiningConfirmModal.modalColor"
      :modalContent="joiningConfirmModal.modalContent"
      :isShowPopup="joiningConfirmModal.isShowPopup"
      :buttons="joiningConfirmModal.buttons"
      :modalCallBack="joiningConfirmModalCallBack"
      :closeOnBackdrop="false"
    /> -->
    <Modal
      v-if="resubmitConfirmModal.isShowPopup"
      :modalTitle="resubmitConfirmModal.modalTitle"
      :modalColor="resubmitConfirmModal.modalColor"
      :modalContent="resubmitConfirmModal.modalContent"
      :isShowPopup="resubmitConfirmModal.isShowPopup"
      :buttons="resubmitConfirmModal.buttons"
      :modalCallBack="resubmitConfirmModalCallBack"
      :closeOnBackdrop="false"
    />
    <EmailModal
      v-if="emailModal.email"
      :isShowPopup="emailModal.email"
      :email_id="emailId"
      :subject="emailModal.subject"
      :message="emailModal.message"
      :candidate_uid="emailModal.candidate_uid"
      @emailModalCallBack="modalCallBackEmail"
      @conversationModalCallBack="conversationModalCallBack"
    />
    <DeleteModal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :delete_id="deleteModal.delete_id"
      :email_subject="deleteModal.subject"
      @deleteModalCallBack="deleteModalCallBack"
      @emailModalCallBack="modalCallBack"
    />
    <OfferOnboardModal
      v-if="offerOnboardModal.isShowPopup"
      :isShowPopup="offerOnboardModal.isShowPopup"
      :title="offerOnboardModal.title"
      :buttons="offerOnboardModal.buttons"
      :isOnBoard="offerOnboardModal.isOnBoard"
      :isOffer="offerOnboardModal.isOffer"
      :isDocsView="offerOnboardModal.isDocsView"
      :infoData="offerOnboardModal.data"
      :candidate="offerOnboardModal.candidate"
      :job="offerOnboardModal.job"
      @modalCallBack="offerOnboardModalCallBack"
    />
    <CandidateInfoModal
      v-if="candidateInfoModal.isShowPopup"
      :candidate_uid="candidateInfoModal.candidate_uid"
      :isShowModal="candidateInfoModal.isShowPopup"
      @modalCallBack="candidateInfoModalCallBack"
    />
    <JobInfoModal
      v-if="jobInfoModal.isShowPopup"
      :job_id="jobInfoModal.job_id"
      :isShowModal="jobInfoModal.isShowPopup"
      @modalCallBack="jobInfoModalCallBack"
    />
    <InterviewFeedBackModal
      v-if="interviewFeedBackModal.isShowPopup"
      :feedBackInfo="interviewFeedBackModal.data"
      :isShowPopup="interviewFeedBackModal.isShowPopup"
      @modalCallBack="interviewFeedBackModalCallBack"
    />
    <ActionModal
      v-if="actionModal.isShowPopup"
      :isShowPopup="actionModal.isShowPopup"
      :details="actionModal.details"
      :action="actionModal.action"
      @modalCallBack="actionModalCallBack"
    />
    <Modal
      :modalTitle="submissionModal.modalTitle"
      :modalColor="submissionModal.modalColor"
      :modalContent="submissionModal.modalContent"
      :isShowPopup="submissionModal.isShowPopup"
      :buttons="submissionModal.buttons"
      :modalCallBack="submissionModalCallBack"
      :closeOnBackdrop="submissionModal.closeOnBackdrop"
      v-if="submissionModal.isShowPopup"
    />
    <Modal
      v-if="interViewModal.isShowPopup"
      :modalTitle="interViewModal.modalTitle"
      :modalColor="interViewModal.modalColor"
      :modalContent="interViewModal.modalContent"
      :isShowPopup="interViewModal.isShowPopup"
      :buttons="interViewModal.buttons"
      :modalCallBack="interViewModalCallBack"
      :closeOnBackdrop="false"
    />
    <!-- <OfferOnBoardModal
      v-if="offerOnBoardModal.isShowPopup"
      :isShowPopup="offerOnBoardModal.isShowPopup"
      :title="offerOnBoardModal.title"
      :infoData="offerOnBoardModal.details"
      :action="offerOnBoardModal.action"
      :isOnBoard="offerOnBoardModal.isOnBoard"
      :isOffer="offerOnBoardModal.isOffer"
      @actionCallBack="offerOnBoardModalCallBack"
    /> -->
    <FastTrackModal
      v-if="fastTrackModal.isShowPopup"
      :isShowPopup="fastTrackModal.isShowPopup"
      :details="fastTrackModal.details"
      :action="fastTrackModal.action"
      @modalCallBack="fastTrackModalCallBack"
      :candidateDetail="fastTrackModal.candidate"
      :jobDetail="fastTrackModal.job"
    />
    <OnErrorModal
      v-if="errorModal.isShowPopup"
      :isShowPopup="errorModal.isShowPopup"
      @modalCallBack="errorModalCallBack"
      :candidate="errorModal.candidate"
      :job="errorModal.job"
      :action="errorModal.action"
    />
    <JoiningModal
      v-if="joiningConfirmModal.isShowPopup"
      :isShowPopup="joiningConfirmModal.isShowPopup"
      @modalCallBack="joiningConfirmModalCallBack"
    />
    <Modal
      v-if="notificationModal.isShowPopup"
      :modalTitle="notificationModal.modalTitle"
      :modalColor="notificationModal.modalColor"
      :modalContent="notificationModal.modalContent"
      :isShowPopup="notificationModal.isShowPopup"
      :buttons="notificationModal.buttons"
      :modalCallBack="notificationModalCallBack"
      :closeOnBackdrop="false"
    />
    <CheckListModal
      v-if="checkListModal.isShowPopup"
      :isShowPopup="checkListModal.isShowPopup"
      @modalCallBack="checkListModalCallBack"
      :candidateDetails="checkListModal.candidateDetails"
      :jobDetails="checkListModal.jobDetails"
      :actionInfo="checkListModal.actionInfo"
      :isActionCompleted="checkListModal.isActionCompleted"
      :activeTab="checkListModal.activeTab"
      :accordion="checkListModal.accordion"
      @uploadDocActionCallBack="uploadDocActionCallBack"
    />
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import SlotModal from "@/containers/Dashboard/RecruiterDashBoard/SlotModal";
import Select from "@/components/reusable/Fields/Select.vue";
import Vue from "vue";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Modal from "@/components/reusable/Modal";
import EmailModal from "@/containers/Communication/EmailModal";
import DeleteModal from "@/containers/Communication/DeleteModal";
import JobApproval from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/JobApproval";
import CandidateSubmission from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/CandidateSubmission";
import CandidateSchedule from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/CandidateSchedule";
import CandidateInterview from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/CandidateInterview";
import CandidateOffered from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/CandidateOffered";
import CandidateOnboard from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/CandidateOnboard";
import CandidateJoining from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/CandidateJoining";
import CandidateInfoModal from "@/components/reusable/CandidateInfoModal";
import JobInfoModal from "@/components/reusable/JobInfoModal";
import CVRequest from "@/containers/Dashboard/RecruiterDashBoard/JobCandidateStatus/CVRequest";
import JoiningModal from "@/containers/Dashboard/RecruiterDashBoard/JoiningModal";
import AddExtraInterviewer from "@/components/reusable/GenericTabFilters/AddExtraInterviewer";

import { Role, getDaysFromDate, isEmptyObjectCheck } from "@/helpers/helper";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";

import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";

import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import CropperModal from "@/components/reusable/CropperModal";

import { getLocalOrgDetail, isObject } from "@/helpers/helper";
import { extend } from "vee-validate";
import { required, regex, min, numeric } from "vee-validate/dist/rules";

import OfferOnboardModal from "@/components/reusable/OfferOnBoardModal";
import InterviewFeedBackModal from "@/components/reusable/interviewFeedBackModal";
import ThreeDotsActions from "@/containers/Dashboard/RecruiterDashBoard/ThreeDotsActions";
import FastTrackModal from "@/components/reusable/GenericTabFilters/FastTrackModal";
import RAGMixins from "@/containers/Dashboard/RecruiterDashBoard/RAGMixins";
import CheckListModal from "@/components/reusable/CheckList/CheckListModal";

extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});

export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
    isDefaultShow: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [ThreeDotsActions, RAGMixins],
  components: {
    SlotModal,
    EmailModal,
    DeleteModal,
    Select,
    DatePicker,
    TextInput,
    Modal,
    JobApproval,
    CandidateSubmission,
    CandidateSchedule,
    CandidateInterview,
    PhoneInputwithCountry,
    CandidateOffered,
    CandidateOnboard,
    PreviewModal,
    UploadDocument,
    CropperModal,
    TextAreaInput,
    CandidateJoining,
    OfferOnboardModal,
    CandidateInfoModal,
    JobInfoModal,
    CVRequest,
    InterviewFeedBackModal,
    RadioButton,
    JoiningModal,
    AddExtraInterviewer,
    CheckListModal,
  },
  data() {
    return {
      Role,
      candidate_document: {},
      custombtn: false,
      documenttitle_id: null,
      addbtnshow: true,
      org_doc_ids: [],
      documenttitle_id: null,

      jobreapproval_jobaction_id: null,
      comments_data: [],
      can_document_id: null,
      onboardtableid: null,
      organisation_id: null,
      mark_action_id: 0,
      onboarddata: null,
      onvaultdata: null,
      showbtn: false,
      offerconfimrbtnjob: 0,
      uploadData: {},
      isFileError: false,
      isFileTpeValid: false,
      FileTypeError: null,
      isImageSelected: false,
      fileData: {},
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
      },
      offerModel: {
        isShowPopup: false,
        data: null,
      },
      emailId: null,
      emailModal: {
        email: false,
        candidate_uid: null,
        subject: null,
        message: null,
      },
      deleteModal: {
        isShowPopup: false,
        delete_id: null,
        subject: null,
      },
      offerDocumentModel: {
        isShowPopup: false,
        data: null,
      },
      disabledButton: [],
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      confirmationModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["Ok"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      offerConfirmModal: {
        modalColor: "primary",
        modalTitle: "Offer Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      offerDocumentConfirmModal: {
        modalColor: "primary",
        modalTitle: "Document Upload",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      onboardConfirmModal: {
        modalColor: "primary",
        modalTitle: "OnBoard Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      joiningConfirmModal: {
        modalColor: "primary",
        modalTitle: "Joining Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      resubmitConfirmModal: {
        isjobreapproval: false,
        modalColor: "primary",
        modalTitle: "Job Reapproval",
        modalContent: "Please confirm you are sending this job for reapproval",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      onVaultconfirmModal: {
        modalColor: "primary",
        modalTitle: "OnVault Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      onjobreapprovalConfirmModal: {
        modalColor: "primary",
        modalTitle: "Job Reapproval Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      alert1: {
        job_approval: { code: "pending", label: "Pending" },
        submission: { code: "pending", label: "Pending" },
        interview: { code: "pending", label: "Pending" },
        scheduled: { code: "pending", label: "Pending" },
        offered: { code: "pending", label: "Pending" },
        onboard: { code: "pending", label: "Pending" },
      },
      candiateFields: [
        { label: "Candidate ID", key: "candidate_id", _style: "width: 10%" },
        {
          label: "Candidate Name",
          key: "candidate_name",
          _style: "width: 15%",
        },
        { label: "Job ID", key: "job_id", _style: "width: 5%" },
        { label: "Job Title", key: "job_title", _style: "width: 20%" },
        // { key: "requested_by", label: "Requested By", _style: "width: 15%" },
        // {
        //   label: "Initiated Date",
        //   key: "initiated_date",
        //   _style: "width: 10%",
        // },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 5%",
        },
        { key: "approved_by", label: "Approval By", _style: "width: 10%" },
        { label: "Status", key: "status", _style: "width: 10%" },
        { label: "Comments", key: "comments", _style: "width: 20%" },
        { key: "action", label: "Action", _style: "width:5%" },
      ],
      jobFields: [
        { key: "job_id", label: "Job ID", _style: "width: 7%" },
        { key: "job_title", label: "Job Title", _style: "width: 13%" },
        // { key: "requested_by", label: "Requested By", _style: "width: 10%" },
        // {
        //   key: "initiated_date",
        //   label: "Initiated Date",
        //   _style: "width: 10%",
        // },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 12%",
        },
        { key: "approved_by", label: "Approval By", _style: "width: 18%" },
        { key: "status", label: "Status", _style: "width: 20%" },
        { key: "comments", label: "Comments", _style: "width: 25%" },
        { key: "action", label: "Action", _style: "width:5%" },
      ],
      interviewFields: [
        { label: "Candidate ID", key: "candidate_id", _style: "width: 10%" },
        {
          label: "Candidate Name",
          key: "candidate_name",
          _style: "width: 15%",
        },
        { label: "Job ID", key: "job_id", _style: "width: 5%" },
        { label: "Job Title", key: "job_title", _style: "width: 13%" },
        // { key: "requested_by", label: "Requested By", _style: "width: 15%" },
        // {
        //   label: "Initiated Date",
        //   key: "initiated_date",
        //   _style: "width: 10%",
        // },
        {
          key: "days_for_approval",
          label: "Days until Interview",
          _style: "width: 7%",
        },
        { key: "approved_by", label: "Interviewer(s)", _style: "width: 15%" },
        { label: "Status", key: "status", _style: "width: 13%" },
        { label: "Comments", key: "comments", _style: "width: 17%" },
        { key: "action", label: "Action", _style: "width:5%" },
      ],

      offeredFields: [
        { label: "Candidate ID", key: "candidate_id", _style: "width: 10%" },
        {
          label: "Candidate Name",
          key: "candidate_name",
          _style: "width: 15%",
        },
        { label: "Job ID", key: "job_id", _style: "width: 5%" },
        { label: "Job Title", key: "job_title", _style: "width: 13%" },
        // { key: "requested_by", label: "Requested By", _style: "width: 15%" },
        // {
        //   label: "Initiated Date",
        //   key: "initiated_date",
        //   _style: "width: 10%",
        // },
        {
          key: "days_for_approval",
          label: "Days until Interview",
          _style: "width: 7%",
        },
        { key: "approved_by", label: "Interviewer(s)", _style: "width: 15%" },
        { label: "Status", key: "status", _style: "width: 13%" },
        { label: "Comments", key: "comments", _style: "width: 17%" },
        { key: "action", label: "Action", _style: "width:5%" },
      ],
      onboardFields: [
        { label: "Candidate ID", key: "candidate_id", _style: "width: 10%" },
        {
          label: "Candidate Name",
          key: "candidate_name",
          _style: "width: 15%",
        },
        { label: "Job ID", key: "job_id", _style: "width: 5%" },
        { label: "Job Title", key: "job_title", _style: "width: 13%" },
        // { key: "requested_by", label: "Requested By", _style: "width: 15%" },
        // {
        //   label: "Initiated Date",
        //   key: "initiated_date",
        //   _style: "width: 10%",
        // },
        {
          key: "days_for_approval",
          label: "Days until Interview",
          _style: "width: 7%",
        },
        { key: "approved_by", label: "Interviewer(s)", _style: "width: 15%" },
        { label: "Status", key: "status", _style: "width: 13%" },
        { label: "Comments", key: "comments", _style: "width: 17%" },
        { key: "action", label: "Action", _style: "width:5%" },
      ],
      slotModal: {
        isShowPopup: false,
        slots: null,
      },
      jobExpiryFields: [
        { key: "check_box", _style: "width:10%" },
        { key: "job_id", label: "Job ID", _style: "width: 15%" },
        { key: "job_title", label: "Job Title", _style: "width: 25%" },
        { key: "expiry_date", label: "Expiry Date", _style: "width: 15%" },
        {
          key: "days_to_inactive",
          label: "Days to inactive",
          _style: "width: 15%;",
        },
        { key: "action", label: "Action", _style: "width: 20%" },
      ],
      jobAccordionFields: [
        { key: "initiated_date", label: "Date", _style: "width: 20%" },
        { key: "requested_by", label: "Name", _style: "width: 20%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 30%" },
        { key: "action", label: "Action", _style: "width: 15%" },
      ],
      jobAccordionSubmittedFields: [
        { key: "initiated_date", label: "Date", _style: "width: 20%" },
        { key: "requested_by", label: "Name", _style: "width: 20%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 30%" },
        { key: "action", label: "Action", _style: "width: 15%" },
      ],
      jobAccordionScheduledFields: [
        { key: "initiated_date", label: "Date", _style: "width: 15%" },
        { key: "requested_by", label: "Name", _style: "width: 15%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 40%" },
        { key: "slots", label: "Action/Slot(s)", _style: "width: 25%" },
      ],
      jobAccordionInterviewFields: [
        { key: "initiated_date", label: "Date", _style: "width: 20%" },
        { key: "requested_by", label: "Name", _style: "width: 20%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 35%" },
        {
          key: "selected_slot",
          label: "Selected Slot/Action",
          _style: "width: 20%",
        },
      ],
      jobAccordionOfferedFields: [
        { key: "initiated_date", label: "Date", _style: "width: 20%" },
        { key: "requested_by", label: "Name", _style: "width: 20%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 35%" },
        {
          key: "selected_slot",
          label: "Selected Slot/Action",
          _style: "width: 20%",
        },
      ],
      jobAccordionOnboardFields: [
        { key: "initiated_date", label: "Date", _style: "width: 20%" },
        { key: "requested_by", label: "Name", _style: "width: 20%" },
        // { key: "approved_by", label: "Approval By", _style: "width: 20%" },
        { key: "display_status", label: "Status", _style: "width: 15%" },
        { key: "comments", label: "Comments", _style: "width: 35%" },
        {
          key: "selected_slot",
          label: "Selected Slot/Action",
          _style: "width: 20%",
        },
      ],

      collapseDuration: 0,
      // collapseIndex: null,
      isTransactionFetch: false,
      expiryUpdateModel: {
        isShowPopup: false,
        data: null,
        expiry_review_date: null,
      },
      submissionActionData: [],
      confirmModal: {
        modalColor: "primary",
        modalTitle: "Edit Job",
        modalContent: "Please confirm you would like to edit this job?",
        isShowPopup: false,
        job_id: null,
        buttons: ["Cancel", "Confirm"],
      },
      resubmitModel: {
        isShowPopup: false,
        data: null,
        isResubmit: false,
        isReinstate: false,
        isReqInterview: false,
        isInterviewSlot: false,
        isOfferedstate: false,
        isOfferDocument: false,
        isOnboardDocument: false,
        isViewDocs: false,
        isVault: false,
        isjobreapproval: false,
        title: "",
      },
      commentsData: {},
      profile: {},
      payload: {},
      reinstate_action: {
        action_id: 10,
        directAction: null,
        filter_id: 7,
        label: "Re-Submitted",
        reason_id: null,
        status: null,
        tab_id: 2,
      },
      interview_action: {
        action_id: 6,
        directAction: null,
        filter_id: 28,
        label: "Interview",
        reason_id: null,
        status: null,
        tab_id: 4,
        sub_status_for_request: 51, // Interview Requested
        sub_status_for_schedule: 55, // Scheduled
      },
      offer_action: {
        action_id: 7,
        directAction: null,
        filter_id: 28,
        label: "Offer",
        reason_id: null,
        status: null,
        tab_id: 4,
      },
      onboard_action: {
        action_id: 8,
        directAction: null,
        filter_id: 29,
        label: "Onboard",
        reason_id: null,
        status: null,
        tab_id: 4,
      },
      previously_selected_row: null,
      displayTab: {
        job_approval: true,
        job_expiry: false,
        cv_request: false,
        submission: false,
        schedule: false,
        interview: false,
        offered: false,
        onboard: false,
        joining: false,
      },
      activeTab: 0,
      currentTabfordocument: null,
      activeTabForDoc: null,
      documentShowModal: {
        isShowPopup: false,
      },
      joining_data: null,
      onboardViewDoc: false,
      offerViewDoc: false,
      docActiveTab: 0,
      activeOfferDocTab: null,
      activeOnboardDocTab: null,
      transaction_job_id: null,
      selected_job_docs: [],
      selectedAll: false,
      onJobTab: false,
      offerOnboardModal: {
        isShowPopup: false,
        data: null,
        title: "",
        buttons: [],
        isOnBoard: false,
        isOffer: false,
        isDocsView: false,
      },
      documentDisplayModal: {
        isShowPopup: false,
      },
      candidateInfoModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
      jobInfoModal: {
        isShowPopup: false,
        job_id: null,
      },
      selectedJobID: [],
      interviewFeedBackModal: {
        isShowPopup: false,
        data: null,
      },
      notificationModal: {
        modalColor: "danger",
        modalTitle: "Notification",
        modalContent:
          "This account is entitled only up to Offer; please contact your Administrator for further access!",
        buttons: ["OK"],
        isShowPopup: false,
      },
      showAddInterviewer: false,
      showAlert: this.isDefaultShow,
    };
  },
  computed: {
    ...mapGetters([
      "getSubmissionAction",
      "getInterviewRequestAction",
      "getInterviewScheduleAction",
      "getJobApprovalAction",
      "getJobsForDashboard",
      "getJobsForAccordion",
      "getTabReasons",
      "getSubStatusOptions",
      "getDashboardTabCount",
      "getCollapseIndex",
      "getActiveDashboardTab",
      "dialingCodeWithCountryCode",
      "getJobDocuments",
      "documentType",
      "documentTypeOffer",
      "getUser",
      "getOfferDetailsforCandidate",
      "getUploadedDocument",
      "uploaded",
      "documentType",
      "getOfferActionDetails",
      "getOnboardActionDetails",
      "getAllDocumentByOrganisationId",
      "fetchOffer",
      "getOnboardDetailsforCandidate",
      "getOrgDocumentTypes",
      "getActionComments",
      "documentTypeforOffer",
      "documentTypeForOnboard",
      "getorgdocsid",
      "getCustomerId",
      "getJoiningActionDetails",
      "isCandidateSupplierFromAccessToken",
      "onboardDocumentActions",
      "isJobSupplierFromAccessToken",
      "isUKMainAgency",
      "getOrgIDFromAccessToken",
      "getCVRequest",
      "interviewFeedBack",
    ]),
    options() {
      return {
        document_type: this.documentTypeExcPhotoCustomOrder || [],
        comments_options: this.comments_data || [],
        action_status: [
          { code: "pending", label: "Pending" },
          { code: "completed", label: "Completed" },
        ],
        priority_level: [
          { code: 1, label: 1 },
          { code: 2, label: 2 },
          { code: 3, label: 3 },
          { code: 4, label: 4 },
        ],
        sub_status: this.filteredSubStatus || [],
        reason_code: this.getTabReasons || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        alert_days: [
          { code: 1, label: "1 Day" },
          { code: 3, label: "3 Days" },
          { code: 7, label: "7 Days" },
          { code: "custom", label: "Customize" },
        ],
      };
    },
    documentTypeExcPhotoCustomOrder() {
      if (
        this.resubmitModel.isOfferedstate ||
        this.resubmitModel.isOfferDocument
      ) {
        return (
          (this.documentTypeforOffer &&
            this.documentTypeforOffer.length &&
            this.documentTypeforOffer.sort((a, b) =>
              a.label === "Terms & Conditions" ? -1 : 1
            )) ||
          []
        );
      } else if (this.resubmitModel.isOnboardDocument) {
        return (
          (this.documentTypeForOnboard &&
            this.documentTypeForOnboard.length &&
            this.documentTypeForOnboard.sort((a, b) =>
              a.label === "Terms & Conditions" ? -1 : 1
            )) ||
          []
        );
      }
    },
    documentType_Offer() {
      return (
        (this.documentTypeOffer &&
          this.documentTypeOffer.length &&
          this.documentTypeOffer.sort((a, b) =>
            a.label === "Terms & Conditions" ? -1 : 1
          )) ||
        []
      );
    },
    candidateDocumentforonboard() {
      let jobId = [];
      if (this.onboarddata != null) {
        jobId = this.onboarddata != null ? this.onboarddata.job_id : null;

        return (
          (this.getUploadedDocument &&
            this.getUploadedDocument?.length &&
            this.getUploadedDocument
              .filter((docs) => {
                return (
                  [25].includes(docs.document_type_id) &&
                  [jobId].includes(docs.job_id)
                );
              })
              .map(
                ({
                  document_type_id,
                  link_to_doc,
                  document_name,
                  document_type,
                  created_on,
                  document_ext,
                  candidate_document_id,
                  comments,
                  actionable,
                  org_type,
                  candidate_uid,
                }) => {
                  return {
                    link: link_to_doc,
                    label: comments,
                    name: document_name,
                    updatedDate: moment(created_on).format("DD-MM-YYYY"),
                    document_ext: document_ext?.document_ext,
                    document_id: candidate_document_id,
                    actionable: actionable,
                    org_type: org_type,
                    candidate_uid: candidate_uid,
                  };
                }
              )) ||
          []
        );
      } else {
        return [];
      }
    },
    candidateDocument() {
      let jobId = [];
      if (
        this.offerModel.data != null ||
        this.offerDocumentModel.data != null
      ) {
        jobId =
          this.offerModel.data != null
            ? this.offerModel.data.job_id
            : this.offerDocumentModel.data.job_id;

        return (
          (this.getUploadedDocument &&
            this.getUploadedDocument?.length &&
            this.getUploadedDocument
              .filter((docs) => {
                return (
                  [27, 29].includes(docs.document_type_id) &&
                  [jobId].includes(docs.job_id)
                );
              })
              .map(
                ({
                  candidate_uid,
                  document_type_id,
                  link_to_doc,
                  document_name,
                  document_type,
                  created_on,
                  document_ext,
                  candidate_document_id,
                  comments,
                  actionable,
                  org_type,
                }) => {
                  return {
                    link: link_to_doc,
                    label: comments,
                    name: document_name,
                    updatedDate: moment(created_on).format("DD-MM-YYYY"),
                    document_ext: document_ext?.document_ext,
                    document_id: candidate_document_id,
                    actionable: actionable,
                    org_type: org_type,
                    candidate_uid,
                  };
                }
              )) ||
          []
        );
      } else {
        return [];
      }
    },
    candidateUploadedDocuments() {
      return this.getUploadedDocument?.length
        ? _(this.getUploadedDocument)
            .map(
              ({
                candidate_uid,
                document_type_id,
                link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_id,
                version_no,
                comments,
                actionable,
                job_id,
              }) => {
                return {
                  candidate_uid: candidate_uid,
                  document_type_id: document_type_id,
                  link: link_to_doc,
                  label: comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_id,
                  version_no: version_no,
                  actionable: actionable,
                  job_id: job_id,
                };
              }
            )
            .value()
        : [];
    },
    candidateOrganisationOfferDocuments() {
      return this.candidateUploadedDocuments?.length
        ? _(this.candidateUploadedDocuments)
            .filter((docs) => {
              return (
                [27, 29].includes(docs.document_type_id) &&
                this.transaction_job_id == docs?.job_id
              );
            })
            .value()
        : [];
    },
    candidateUploadedOfferDocuments() {
      return this.candidateUploadedDocuments?.length
        ? _(this.candidateUploadedDocuments)
            .filter((docs) => {
              return (
                [28, 30].includes(docs.document_type_id) &&
                this.transaction_job_id == docs?.job_id
              );
            })
            .value()
        : [];
    },
    candidatePendingOfferDocuments() {
      let org_docs = this.candidateOrganisationOfferDocuments;
      for (var i = org_docs?.length - 1; i >= 0; i--) {
        for (var j = 0; j < this.candidateUploadedOfferDocuments?.length; j++) {
          if (
            org_docs[i] &&
            org_docs[i].actionable &&
            org_docs[i].label === this.candidateUploadedOfferDocuments[j].label
          ) {
            org_docs.splice(i, 1);
          }
        }
      }
      return _(org_docs)
        .filter((val) => val.actionable)
        .value();
    },
    candidateOrganisationOnboardDocuments() {
      return this.candidateUploadedDocuments?.length
        ? _(this.candidateUploadedDocuments)
            .filter((docs) => {
              return [25].includes(docs.document_type_id);
            })
            .value()
        : [];
    },
    candidateUploadedOnboardDocuments() {
      return this.candidateUploadedDocuments?.length
        ? _(this.candidateUploadedDocuments)
            .filter((docs) => {
              return [26].includes(docs.document_type_id);
            })
            .value()
        : [];
    },
    candidatePendingOnboardDocuments() {
      let org_docs = this.candidateOrganisationOnboardDocuments;
      for (var i = org_docs?.length - 1; i >= 0; i--) {
        for (
          var j = 0;
          j < this.candidateUploadedOnboardDocuments?.length;
          j++
        ) {
          if (
            org_docs[i] &&
            org_docs[i].actionable &&
            org_docs[i].label ===
              this.candidateUploadedOnboardDocuments[j].label
          ) {
            org_docs.splice(i, 1);
          }
        }
      }
      return _(org_docs)
        .filter((val) => val.actionable)
        .value();
    },
    showSendAll() {
      return this.getUploadedDocument && this.getUploadedDocument?.length
        ? _(this.getUploadedDocument).some(
            ({ document_type_id }) => document_type_id == 29
          ) || false
        : false;
    },
    candidateDocument_offerLetter() {
      return (
        this.getUploadedDocument &&
        this.getUploadedDocument?.length &&
        this.getUploadedDocument
          .filter((docs) => {
            return (
              [28, 30].includes(docs.document_type_id) &&
              this.transaction_job_id == docs.job_id
            );
          })
          .map(
            ({
              candidate_uid,
              document_type_id,
              link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_id,
              version_no,
              comments,
              actionable,
            }) => {
              return {
                candidate_uid: candidate_uid,
                link: link_to_doc,
                label: comments,
                name: document_name,
                updatedDate: moment(created_on).format("DD-MM-YYYY"),
                document_ext: document_ext?.document_ext,
                document_id: candidate_document_id,
                version_no: version_no,
                actionable: actionable,
              };
            }
          )
      );
    },
    getLatestVersionForCandidateOffer() {
      return this.candidateDocument_offerLetter?.length
        ? _.maxBy(this.candidateDocument_offerLetter, "version_no")
        : [];
    },
    candidateDocumentAfterUplaod() {
      return (
        (this.getUploadedDocument &&
          this.getUploadedDocument?.length &&
          this.getUploadedDocument
            .filter((docs) => {
              return [26].includes(docs.document_type_id);
            })
            .map(
              ({
                document_type_id,
                link_to_doc,
                document_name,
                comments,
                created_on,
                document_ext,
                candidate_document_id,
              }) => {
                return {
                  link: link_to_doc,
                  label: comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_id,
                };
              }
            )) ||
        []
      );
    },
    filteredSubStatus() {
      return (
        this.getSubStatusOptions.filter(
          ({ code }) => code != this.details?.sub_status?.status_id
        ) || []
      );
    },
    comments_for_recruiter() {
      return (
        this.getActionComments.filter(
          ({ code }) => code != this.details?.comments
        ) || []
      );
    },
    getJobExpiry() {
      return (
        this.getJobsForDashboard.map((v) => ({
          ...v,
          job_id: v?.job_id,
          job_title: v?.job_title,
          job_organisation_name: v?.organisation_name,
          expiry_date: this.formatDate(v?.expiry_review_date),
          days_to_inactive: `${getDaysFromDate(v?.expiry_review_date)} Day(s)`,
          // _classes: this.getRAGColorsForJobExpiry(getDaysFromDate(v?.expiry_review_date))
          circle_color: this.getRAGColorsForJobExpiryCircle(
            getDaysFromDate(v?.expiry_review_date)
          ),
        })) || []
      );
    },
    slots() {
      const dataMap = {
        slot1: {
          available_slot1_date: "date",
          available_slot1_start: "start_time",
          available_slot1_end: "end_time",
        },
        slot2: {
          available_slot2_date: "date",
          available_slot2_start: "start_time",
          available_slot2_end: "end_time",
        },
        slot3: {
          available_slot3_date: "date",
          available_slot3_start: "start_time",
          available_slot3_end: "end_time",
        },
      };
      const name = { slot1: "Slot 1", slot2: "Slot 2", slot3: "Slot 3" };
      const res = [this.getJobsForAccordion[0]]
        // .filter((val) => val?.candidate_uid === this.details?.candidate_uid)
        .map((val) => {
          let slots = [];
          for (const key in dataMap) {
            const slotFields = dataMap[key];
            let slot = {};
            for (let [field, value] of Object.entries(slotFields)) {
              if (val[field]) {
                slot[value] = val[field];
              }
            }
            if (!isEmptyObjectCheck(slot)) {
              slot["name"] = name[key];
              slot["action_detail_id"] = val?.action_detail_id;
              slots = [...slots, slot];
            }
          }
          return slots;
        });
      return res.length ? res[0] : [];
    },
    accordionTransactionsCount() {
      return this.getJobsForAccordion?.filter((val) => {
        // 67 - Review (Shortlist 1), 68 - Review (Shortlist 2)
        return ![67, 68].includes(val?.status_id);
      }).length;
    },
    getInterviewTabCount() {
      return (
        this.getInterviewScheduleAction?.filter((val) => {
          const isNull =
            this.alert1["interview"]?.code === "pending" ? true : false;
          if (isNull) return !val?.reviewed;
          else return val?.reviewed;
        }) || []
      );
    },
    getScheduledTabCount() {
      return (
        this.getInterviewRequestAction?.filter((val) => {
          const isNull =
            this.alert1["scheduled"]?.code === "pending" ? true : false;
          // if (isNull)
          // // 50 - Approved for Interview, 51 - Interview Requested
          //   return [50, 51].includes(val?.sub_status_id);
          // else
          //   return [54].includes(val?.status_id) && val?.sub_status_id == null;
          if (isNull) return !val?.reviewed;
          else return val?.reviewed;
        }) || []
      );
    },
    getSubmisionTabCount() {
      return (
        this.getSubmissionAction?.filter((val) => {
          const isNull =
            this.alert1["submission"]?.code === "pending" ? true : false;
          // if (isNull)
          // // 53 - Awaiting Feedback, 44 - On Hold
          //   return [53, 44].includes(val?.sub_status_id) && val?.completed_date === null;
          // else
          // // 47 - Disapproved, 48 - Withdrawn, 49 - Reserve, 50 - Approved for Interview
          //   return [47, 48, 49, 50].includes(val?.sub_status_id);
          if (isNull) return !val?.reviewed;
          else return val?.reviewed;
        }) || []
      );
    },
    getOfferedTabCount() {
      return (
        this.getOfferActionDetails?.filter((val) => {
          const isNull =
            this.alert1["offered"]?.code === "pending" ? true : false;
          if (isNull) return !val?.reviewed;
          else return val?.reviewed;
        }) || []
      );
    },
    getOnboardTabCount() {
      return (
        this.getOnboardActionDetails?.filter((val) => {
          const isNull =
            this.alert1["onboard"]?.code === "pending" ? true : false;
          if (isNull) return !val?.reviewed;
          else return val?.reviewed;
        }) || []
      );
    },
    getJoiningTabCount() {
      return this.getJoiningActionDetails;
    },
    //////////////////////////////// start for documents////////////////////////////////
    orgDocuments() {
      return (
        this.getAllDocumentByOrganisationId?.length &&
        this.getAllDocumentByOrganisationId
          .filter((docs) => {
            return [25].includes(docs.document_type_id);
          })
          .map(
            ({
              document_type_id,
              link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              organisation_document_id,
              comments,
            }) => {
              return {
                document_type_id: document_type_id,
                link: link_to_doc,
                label: document_type.comments,
                name: document_name,
                updatedDate: moment(created_on).format("DD-MM-YYYY"),
                document_ext: document_ext?.document_ext,
                document_id: organisation_document_id,
                comments: comments,
              };
            }
          )
      );
    },
    jobDocuments() {
      return (
        this.getJobDocuments?.length &&
        this.getJobDocuments.map(
          ({
            document_type_id,
            link_to_doc,
            document_name,
            document_title,
            document_type,
            created_on,
            document_ext,
            candidate_document_id,
            comments,
            dtype,
            sign_req,
            organisation_document_id,
            job_document_id,
          }) => {
            return {
              document_type_id: document_type_id,
              link: link_to_doc,
              label: comments, // documentlibrary!=null ? documentlibrary.document_name : document_type.comments,
              name: document_name,
              updatedDate: moment(created_on).format("DD-MM-YYYY"),
              document_ext: document_ext?.document_ext,
              document_id: candidate_document_id,
              comments: comments,
              dtype: dtype,
              sign_req: sign_req,
              organisation_document_id: organisation_document_id,
              actionable: sign_req ? sign_req : false,
              showbtn: true,
              job_document_id: job_document_id,
            };
          }
        )
      );
    },
    fileType() {
      if (
        this.resubmitModel.isOfferedstate ||
        this.resubmitModel.isOfferDocument
      ) {
        let fileext = this.documentTypeforOffer;
        if (fileext) {
          if (
            this.uploadData.document_type &&
            this.uploadData.document_type.code
          ) {
            let fileext = this.documentTypeforOffer
              .filter(({ code }) => code === this.uploadData.document_type.code)
              .map((d) => d.allowed_extensions)[0];
            if (fileext) fileext = fileext.map((d) => d.toLowerCase());
            return fileext;
          } else if (this.uploadData.comments_dtype) {
            let fileext = this.documentTypeforOffer
              ?.filter(({ code }) => code === 27)
              .map((d) => d.allowed_extensions)[0];
            fileext = fileext?.map((d) => d.toLowerCase());
            return fileext;
          } else {
            let fileext = this.documentTypeforOffer
              .filter(({ code }) => code === 27)
              .map((d) => d.allowed_extensions)[0];
            fileext = fileext.map((d) => d.toLowerCase());
            return fileext;
          }
        }
        return [];
      } else if (this.resubmitModel.isOnboardDocument) {
        let fileext = this.documentTypeForOnboard;
        if (fileext) {
          if (
            this.uploadData.document_type &&
            this.uploadData.document_type.code
          ) {
            let fileext = this.documentTypeForOnboard
              .filter(({ code }) => code === this.uploadData.document_type.code)
              .map((d) => d.allowed_extensions)[0];
            if (fileext) fileext = fileext.map((d) => d.toLowerCase());
            return fileext;
          } else if (this.uploadData.comments_dtype) {
            let fileext = this.documentTypeForOnboard
              ?.filter(({ code }) => code === 25)
              .map((d) => d.allowed_extensions)[0];
            fileext = fileext?.map((d) => d.toLowerCase());
            return fileext;
          } else {
            let fileext = this.documentTypeForOnboard
              .filter(({ code }) => code === 25)
              .map((d) => d.allowed_extensions)[0];
            fileext = fileext.map((d) => d.toLowerCase());
            return fileext;
          }
        }
        return [];
      }
    },
    //////////////////////////////// end for documents////////////////////////////////
    pendingCVRequest() {
      return (
        (this.getCVRequest &&
          this.getCVRequest.filter((v) =>
            this.isUKMainAgency ? v?.date_uploaded == null : v?.close == false
          )) ||
        []
      );
    },
    completedCVRequest() {
      return (
        (this.getCVRequest &&
          this.getCVRequest.filter((v) =>
            this.isUKMainAgency ? v?.date_uploaded != null : v?.close == true
          )) ||
        []
      );
    },
  },
  mounted() {
    let CurrentTab =
      (this.$router.currentRoute.query.tab
        ? parseInt(this.$router.currentRoute.query.tab)
        : this.getActiveDashboardTab) || 0;
    this.onTabChange(CurrentTab);
    this.callForActions();
    if (this.isJobSupplierFromAccessToken || this.isUKMainAgency) {
      let payload = {
        skip: 0,
        limit: 200,
      };
      if (this.isJobSupplierFromAccessToken) {
        payload = {
          ...payload,
          req_org_id: this.getOrgIDFromAccessToken,
          order_by: "-date_requested",
        };
      } else if (this.isUKMainAgency) {
        payload = {
          ...payload,
          order_by: "-date_requested",
        };
      }
      this.fetchCVRequest(payload).then((res) => {
        if (res) {
          this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
            cv_request: this.pendingCVRequest.length,
          });
        }
      });
    }
    this.organisation_id =
      this.getOrgId ||
      this.getUser?.org_detail?.org_id ||
      getLocalOrgDetail()?.organisation_id;
    // this.fetchAllVersionDocumentByOrgId({
    //   organisation_id: this.organisation_id,
    // });
  },

  methods: {
    ...mapActions([
      "fetchDocumentIdBytitleId",
      "fetchAllVersionDocumentByOrgId",
      "fetchSubmissionActionDetails",
      "fetchInterviewRequestAction",
      "getInterviewScheduledCandidateActionDetails",
      "fetchJobApprovalAction",
      "setCandidateListBackUrl",
      "resetCandidateDetail",
      "fetchJobsForDashboard",
      "fetchTransactionsByJobId",
      "showToast",
      "updateExpiryDate",
      "initUpdateCandidateStatus",
      "hideLoader",
      "markAsCompleted",
      "fetchSubStatusOptions",
      "fetchJobCandidateReasonCode",
      "initFetchContactInfoOptions",
      "initFetchOptions",
      "fetchDocumentType",
      "uploadPhoto",
      "uploadResume",
      "fetchUploadedDocumentbyCandidateId",
      "fetchJobDocumentsByJobID",
      "getOffersForCandidate",
      "deleteDocument",
      "downloadDocument",
      "deleteDocumentByCandidateId",
      "fetchOfferActionDetails",
      "create_offer",
      "create_Onboard",
      "fetchOnBoardActionDetails",
      "getOnboardForCandidateByOfferId",
      "fetchUploadedDocument",
      "fetchOrgDocumentType",
      "updateOfferDocument_customer",
      "fetchCommentsfor_recruiter",
      "update_job_expiryDate",
      "deleteCandidaterecord",
      "createCandidateRecord",
      "fetchJoiningActionDetails",
      "initUpdateJobStatus",
      "createDocumentAction",
      "getOfferDocumentAction",
      "getOnBoardDocumentAction",
      "updateJobDetailExpiryDate",
      "fetchCVRequest",
      "fetchInterviewFeedBack",
      "fetchCandidateJoiningDate",
    ]),
    checkDocExist(data) {
      return this.candidateDocument && this.candidateDocument?.length
        ? _(this.candidateDocument)?.some(
            ({ label }) => label?.toLowerCase() == data?.comments?.toLowerCase()
          )
        : false;
    },
    sendAllDoc() {
      if (this.jobDocuments?.length) {
        return _.forEach(this.jobDocuments, (doc) => {
          const job_id = this.resubmitModel.data.job_id;
          const customer_uid = this.getUser.customer_id;
          this.payload = {
            ...this.payload,
            job_id: job_id,
            customer_uid: customer_uid,
            organisation_id: this.organisation_id,
            organisation_document_id: doc ? doc.organisation_document_id : null,
            actionable: doc ? doc.actionable : null,
            org_type: true,
            candidate_uid: this.resubmitModel.data.candidate_id,
          };
          this.createCandidateRecord(this.payload).then((res) => {
            this.afteruploaded();
          });
        });
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "No Job Documents Found.",
        });
        return;
      }
    },
    callForActions() {
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      let appendAction = [];
      appendAction = [...appendAction, this.fetchSubmissionActionDetails()];
      appendAction = [...appendAction, this.fetchInterviewRequestAction()];
      appendAction = [...appendAction, this.fetchJobsForDashboard()];
      appendAction = [...appendAction, this.fetchOfferActionDetails()];
      appendAction = [...appendAction, this.fetchOnBoardActionDetails()];
      appendAction = [...appendAction, this.fetchJoiningActionDetails()];
      appendAction = [
        ...appendAction,
        this.getInterviewScheduledCandidateActionDetails(),
      ];
      if (this.activeTab != 0) {
        appendAction = [...appendAction, this.fetchJobApprovalAction()];
      }
      Promise.all(appendAction).then((res) => {
        setTimeout(() => {
          this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
            interview: this.getInterviewTabCount.length,
          });
          this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
            schedule: this.getScheduledTabCount.length,
          });
          this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
            submission: this.getSubmisionTabCount.length,
          });
          this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
            offered: this.getOfferedTabCount.length,
          });
          this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
            onboard: this.getOnboardTabCount.length,
          });
          this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
            joining: this.getJoiningTabCount.length,
          });
        }, 100);
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          interview: this.getInterviewTabCount.length,
        });
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          schedule: this.getScheduledTabCount.length,
        });
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          submission: this.getSubmisionTabCount.length,
        });
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          offered: this.getOfferedTabCount.length,
        });
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          onboard: this.getOnboardTabCount.length,
        });
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          joining: this.getJoiningTabCount.length,
        });
      });
    },
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      return "--";
    },
    formatTime(time) {
      let date = new Date().toISOString().split("T")[0];
      if (time) return moment(date + " " + time).format("h:mm a");
      return "--";
    },
    getRAGColorsForJobExpiry(days) {
      if (!Number.isFinite(days)) return "";
      if (days <= 7) return "table-danger";
      if (days > 7 && days <= 15) return "table-amber";
      if (days > 15) return "table-success";
      return "";
    },
    getRAGColors(days) {
      if (!Number.isFinite(days)) return "";
      if (days < 0) return "table-expired";
      if (days == 0 || days == 1 || days == 2) return "table-danger";
      if (days > 2 && days <= 4) return "table-amber";
      if (days >= 5) return "table-success";
      return "";
    },
    getRAGInterviewColors(days) {
      if (!Number.isFinite(days)) return "";
      if (days < 0) return "table-expired";
      if (days == 0 || days == 1) return "table-danger";
      if (days > 1 && days < 3) return "table-amber";
      if (days >= 3) return "table-success";
      return "";
    },
    getRAGColorsCircle(days) {
      if (!Number.isFinite(days)) return "none";
      if (days < 0) return "#dcd5d5";
      if (days == 0 || days == 1 || days == 2) return "#ff0000";
      if (days > 2 && days <= 4) return "#FFBF00";
      if (days >= 5) return "#008000";
      return "none";
    },
    getRAGColorsForJobExpiryCircle(days) {
      if (!Number.isFinite(days)) return "";
      if (days <= 7) return "#ff0000";
      if (days > 7 && days <= 15) return "#FFBF00";
      if (days > 15) return "#008000";
      return "";
    },
    getRAGInterviewColorsCircle(days) {
      if (!Number.isFinite(days)) return "";
      if (days < 0) return "#dcd5d5";
      if (days == 0 || days == 1) return "#ff0000";
      if (days > 1 && days < 3) return "#FFBF00";
      if (days >= 3) return "#008000";
      return "";
    },
    openSlotPopUp(action) {
      if (action.next_action_detail?.available_slot1_date) {
        const dataMap = {
          slot1: {
            available_slot1_date: "date",
            available_slot1_start: "start_time",
            available_slot1_end: "end_time",
          },
          slot2: {
            available_slot2_date: "date",
            available_slot2_start: "start_time",
            available_slot2_end: "end_time",
          },
          slot3: {
            available_slot3_date: "date",
            available_slot3_start: "start_time",
            available_slot3_end: "end_time",
          },
        };
        let slots = [];
        for (const key in dataMap) {
          const slotFields = dataMap[key];
          let slot = {};
          for (let [field, value] of Object.entries(slotFields)) {
            if (action?.next_action_detail[field]) {
              slot[value] = action?.next_action_detail[field];
            }
          }
          if (!isEmptyObjectCheck(slot)) {
            slot["name"] = key;
            slots = [...slots, slot];
          }
        }
        this.slotModal.isShowPopup = true;
        this.slotModal.slots = slots;
      }
    },
    slotModalCallBack(action) {
      this.slotModal.isShowPopup = false;
      this.slotModal.slots = [];
    },
    navToJob(job_id) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push(`/job-list-preview/${job_id}`);
    },
    navToCandidate(candidate_id) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${candidate_id}` });
    },
    handleChangeSelect(name, value) {
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      Vue.set(this.alert1, name, value);
    },
    handleChangeSelectType(name, value) {
      Vue.set(this.uploadData, name, value);
      if (value) {
        if (value.label.toLowerCase() != "Custom Type Document".toLowerCase()) {
          this.addbtnshow = true;
          this.custombtn = false;
        } else {
          this.addbtnshow = false;
          this.custombtn = true;
        }
        this.payload = {
          ...this.payload,
          //comments :value ? value.label : null,
          [name]: value ? value.id || value.code || value : null,
          documenttitle_id: value
            ? value.documenttitle_id || value.documenttitle_id
            : null,
        };
        if (value)
          this.documenttitle_id = value.documenttitle_id
            ? value.documenttitle_id
            : null;

        this.payload = {
          ...this.payload,
          organisation_id: this.organisation_id,
        };
        this.org_doc_ids = [];
        this.fetchDocumentIdBytitleId(this.payload).then((res) => {
          let docs = this.getorgdocsid;
          docs?.length &&
            docs
              .filter(({ candidate_type_ids }) =>
                candidate_type_ids.includes(
                  this.resubmitModel.data.candidate.candidate_type_id
                )
              )
              .map((res) => {
                this.org_doc_ids.push(res.document_id);
              });
        });
      }
    },
    getdocuments() {
      return (
        (this.getAllDocumentByOrganisationId &&
          this.getAllDocumentByOrganisationId.length &&
          this.getAllDocumentByOrganisationId
            .filter(({ organisation_document_id }) =>
              this.org_doc_ids.includes(organisation_document_id)
            )
            .map(
              ({
                document_type_id,
                link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                organisation_document_id,
                comments,
                version_no,
                sign_req,
              }) => {
                return {
                  link: link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: organisation_document_id,
                  comments: comments,
                  document_type_id: document_type_id,
                  version_no: version_no,
                  sign_req,
                };
              }
            )) ||
        []
      );
    },
    adddocument() {
      let orgdocs = this.getdocuments();
      if (orgdocs?.length != 0) {
        const job_id = this.resubmitModel.data.job_id;
        const customer_uid = this.getUser.customer_id;
        this.payload = {
          ...this.payload,
          job_id: job_id,
          customer_uid: customer_uid,
          organisation_id: this.organisation_id,
          organisation_document_id: orgdocs?.length
            ? orgdocs[0].document_id
            : null,
          actionable: orgdocs?.length ? orgdocs[0].sign_req : null,
          org_type: true,
          candidate_uid: this.resubmitModel.data.candidate_id,
        };
        let doc_id = null;
        if (
          this.resubmitModel.isOfferedstate ||
          this.resubmitModel.isOfferDocument
        ) {
          doc_id = 27;
        } else if (this.resubmitModel.isOnboardDocument) {
          doc_id = 25;
        }
        //let temp = this.getUploadedDocument.filter(({ org_type }) => org_type === true)
        let temp =
          this.getUploadedDocument &&
          this.getUploadedDocument?.length &&
          this.getUploadedDocument.filter(
            ({ document_type_id, org_type, documenttitle_id }) =>
              document_type_id === doc_id &&
              org_type === true &&
              documenttitle_id === this.documenttitle_id
          );
        if (temp.length > 0) {
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].org_type) {
              if (temp[i].documenttitle_id == this.documenttitle_id) {
                this.showToast({
                  class: "bg-info text-white",
                  message: "Document Already Exist.",
                });
              } else {
                this.createCandidateRecord(this.payload).then((res) => {
                  this.afteruploaded();
                });
                break;
              }
            }
          }
        } else {
          this.createCandidateRecord(this.payload).then((res) => {
            this.afteruploaded();
          });
        }
      } else {
        this.showToast({
          class: "bg-info text-white",
          message: "Document Not Found.",
        });
      }

      this.uploadData = {};
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    async toggleDetails(item, index, forSubmissionOrInterview, filterCriteria) {
      this.commentsData = {};
      if (this.previously_selected_row) {
        this.previously_selected_row._classes = "none";
      }
      this.previously_selected_row = item;
      item.toggle = true;
      item._classes = "selected-row-table";
      if (this.getCollapseIndex === index) {
        item._classes = "none";
        this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
        this.tooltipHide();
        return;
      }
      let filterStatus = await this.filterCriteriaStatusAndSubStatus(
        filterCriteria
      );
      this.isTransactionFetch = true;
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", index);
      this.tooltipHide();
      let payload = {
        ...filterStatus,
        job_id: item.job_id,
      };
      if (!forSubmissionOrInterview) {
        payload.action_id = 19; // 19 - Job Approval Process
      } else {
        payload.action_id = 18; // 18 - Job Candidate Process
        payload.candidate_uid = item.candidate_uid;
      }
      this.fetchTransactionsByJobId(payload).then((res) => {
        this.isTransactionFetch = false;
        this.$store.commit("SET_DASHBOARD_TAB_COUNT", {
          accordion: this.accordionTransactionsCount,
        });
      });
      if (item.candidate_uid != null) {
        // getting offer for candidate for
        let offerDetails = {
          job_id: item.job_id,
          candidate_uid: item.candidate_uid,
        };
        await this.getOffersForCandidate(offerDetails).then((res) => {
          let offerdata = this.getOfferDetailsforCandidate;
          offerdata.map((res) => {
            this.getOnboardForCandidateByOfferId(res?.offer_id).then((res) => {
              if (res) {
                this.getOnboardDetailsforCandidate.map((res) => {
                  this.can_document_id = res.candidate_document_ids;
                });
              }
            });
          });
        });
        if (filterCriteria === "onboard") {
          const { job_id, candidate_uid, organisation_id } = item;
          await this.getOnBoardDocumentAction({
            job_id,
            action_type: "licensing",
            candidate_uid,
            organisation_id,
          });
        }
      }
      // getting offer for candidate for
    },
    async OnClickDocument(e) {
      const isValid = await this.$refs.DocumentInfo.validate();
      if (!isValid) {
        e.preventDefault();
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
          autohide: 2000,
        });
        setTimeout(() => {
          this.$refs.DocumentInfo.reset();
          this.$refs.DocumentInfo.errors.clear;
        }, 2000);
      }
      let already_exist = false;
      if (this.candidateDocument?.length && this.uploadData?.comments_dtype) {
        already_exist =
          _(this.candidateDocument).some(
            ({ label }) =>
              label?.toLowerCase() ===
              this.uploadData?.comments_dtype?.toLowerCase()
          ) || false;
      }
      if (already_exist) {
        e.preventDefault();
        this.showToast({
          class: "bg-danger text-white",
          message: "Same Document Type already exist!",
          autohide: 2000,
        });
      }
    },
    filterCriteriaStatusAndSubStatus(filterCriteria) {
      let returnResponse = {};
      switch (filterCriteria) {
        case "submission":
          return returnResponse;
        case "scheduled":
          returnResponse = {
            status_id__in: [54],
            sub_status_id__in: [50, 51, "null", 56],
          };
          return returnResponse;
        case "interview":
          returnResponse = {
            status_id__in: [54],
            sub_status_id__in: [47, 55, 57],
          };
          return returnResponse;
        case "offered":
          returnResponse = {
            status_id__in: [54, 58],
            sub_status_id__in: [57, 59, 60],
          };
          return returnResponse;
        case "onboard":
          returnResponse = {
            status_id__in: [62],
            sub_status_id__in: [69],
          };
          return returnResponse;
        case "joining":
          returnResponse = {
            status_id__in: [62],
            sub_status_id__in: [63],
          };
          return returnResponse;
        default:
          return returnResponse;
      }
    },
    updateJobExpiry(item) {
      let job = [item];
      if (this.selectedJobID.length) {
        job = this.getJobExpiry.filter(({ job_id }) =>
          this.selectedJobID.includes(job_id)
        );
      }
      this.expiryUpdateModel.data = job;
      // this.expiryUpdateModel.expiry_review_date = moment(new Date(item?.expiry_review_date)).format("YYYY-MM-DD");
      this.expiryUpdateModel.expiry_review_date = null;
      this.expiryUpdateModel.isShowPopup = true;
    },
    handleDatePickerChange(name, value) {
      Vue.set(
        this.expiryUpdateModel,
        name,
        moment(new Date(value)).format("YYYY-MM-DD")
      );
    },
    handleSendCheck(data, event) {
      if (event?.target?.checked) {
        const job_id = this.resubmitModel.data.job_id;
        const customer_uid = this.getUser.customer_id;
        this.payload = {
          ...this.payload,
          job_id: job_id,
          customer_uid: customer_uid,
          organisation_id: this.organisation_id,
          organisation_document_id: data ? data.organisation_document_id : null,
          actionable: data ? data.sign_req : null,
          org_type: true,
          candidate_uid: this.resubmitModel.data.candidate_id,
        };
        this.createCandidateRecord(this.payload).then((res) => {
          this.afteruploaded();
        });
      } else {
        let candetails = {
          customer_uid: this.resubmitModel.data.customer_uid,
          candidate_uid: this.resubmitModel.data.candidate_id,
          document_id: this.candidate_document_id,
          job_id: this.resubmitModel?.job_id,
        };
        this.deleteCandidaterecord(candetails);
      }
    },
    sendOrRemoveDoc(data, action) {
      if (action == "Add") {
        const job_id = this.resubmitModel.data.job_id;
        const customer_uid = this.getUser.customer_id;
        this.payload = {
          ...this.payload,
          job_id: job_id,
          customer_uid: customer_uid,
          organisation_id: this.organisation_id,
          organisation_document_id: data ? data.organisation_document_id : null,
          actionable: data ? data.actionable : null,
          org_type: true,
          candidate_uid: this.resubmitModel.data.candidate_id,
        };
        this.createCandidateRecord(this.payload).then((res) => {
          this.afteruploaded();
        });
      } else if (action == "Remove") {
        data.actionable = false;
        let candidate_document = this.getUploadedDocument?.length
          ? _(this.getUploadedDocument)
              .filter(({ comments }) => comments == data.comments)
              .value()[0]
          : null;
        let candetails = {
          customer_uid: this.resubmitModel.data.customer_uid,
          candidate_uid: this.resubmitModel.data.candidate_id,
          document_id: candidate_document?.candidate_document_id || null,
        };
        if (candidate_document?.candidate_document_id)
          this.deleteCandidaterecord(candetails);
        else
          this.showToast({
            class: "bg-danger text-white",
            message: "Document Not Found",
          });
      }
    },
    handleJobCheck(data, event) {
      if (event?.target?.checked) {
        data.actionable = true;
      } else {
        data.actionable = false;
      }
    },
    handleCheck(data, event) {
      const values = Array.from(
        document.querySelectorAll('input[type="checkbox"]')
      )
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value);
      if (!values.length) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Select atleast one actionable document!",
        });
      } else {
        if (event?.target?.checked) {
          let document_payload = {
            candidate_uid: data.candidate_uid,
            customer_uid: this.getCustomerId,
            actionable: true,
            candidate_document_id: data.document_id,
          };
          this.updateOfferDocument_customer(document_payload);
        } else {
          let document_payload = {
            candidate_uid: data.candidate_uid,
            customer_uid: this.getCustomerId,
            actionable: false,
            candidate_document_id: data.document_id,
          };
          this.updateOfferDocument_customer(document_payload);
        }
      }
    },
    handleCheckJobID(job_id, checked) {
      if (checked && !this.selectedJobID.includes(job_id)) {
        this.selectedJobID.push(job_id);
      } else if (!checked) {
        const index = this.selectedJobID.indexOf(job_id);
        if (index > -1) this.selectedJobID.splice(index, 1);
      }
    },
    handleCheckAllJobID(checked) {
      if (checked) {
        const job_ids = this.getJobExpiry.map(({ job_id }) => job_id) || [];
        this.selectedJobID = [...job_ids];
      } else {
        this.selectedJobID = [];
      }
    },
    handleDateChange(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(
        this.payload,
        name,
        value ? moment(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
    handlecomments(name, value) {
      if (value) {
        Vue.set(this.commentsData, name, value.label);
        Vue.set(this.profile, "comments", value.label);
        Vue.set(this.payload, "comments", value.label);
      }
    },
    handlecommentsforrecruiter(name, value) {
      if (value) {
        Vue.set(this.commentsData, name, value.label);
        Vue.set(this.profile, "comments", value.label);
        Vue.set(this.payload, "comments", value.label);
      }
    },
    handleSelect(name, value, action_data = null) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };

      if (["sub_status_id"].includes(name) && action_data) {
        const { action_id, label, filter_id, tab_id } = action_data;
        this.fetchJobCandidateReasonCode({
          tab_id,
          filter_id,
          action_id,
          sub_status_id: this.payload["sub_status_id"],
        }).then((res) => {
          if (this.getTabReasons.length === 1)
            this.handleSelect("reason_id", this.getTabReasons[0]);
        });
      }
    },
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
    handleInput_offer(name, value) {
      //  Vue.set(this.profile, name, value);
      Vue.set(this.uploadData, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if (name === "alert_days" && val != "custom") {
        this.handleDateChange("expiry_date", moment().add(val, "d").toDate());
      }
    },
    handleRadioCustom(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
    onCancelClicked() {
      this.expiryUpdateModel.isShowPopup = false;
      this.resubmitModel.isShowPopup = false;
      this.resubmitModel.isResubmit = false;
      this.resubmitModel.isReinstate = false;
      this.resubmitModel.isReqInterview = false;
      this.resubmitModel.isInterviewSlot = false;
      this.resubmitModel.isOfferedstate = false;
      this.resubmitModel.isOfferDocument = false;
      this.resubmitModel.isOnboardDocument = false;
      this.resubmitModel.isViewDocs = false;
      this.resubmitModel.isVault = false;
      this.resubmitModel.isjobreapproval = false;
      this.commentsData = {};
      this.onJobTab = false;
    },
    onUpdateClicked() {
      if (!this.expiryUpdateModel.expiry_review_date) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please select a date!",
        });
        return;
      }
      if (!this.expiryUpdateModel.data.length) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please select job!",
        });
        return;
      }
      const job_ids = this.expiryUpdateModel.data.map(({ job_id }) => job_id);

      let payload = {
        customer_uid: this.expiryUpdateModel.data[0].customer_uid,
        expiry_date: this.expiryUpdateModel.expiry_review_date,
        job_ids,
      };
      this.updateJobDetailExpiryDate(payload).then(() => {
        this.selectedJobID = [];
        this.fetchJobsForDashboard();
      });
      this.expiryUpdateModel.isShowPopup = false;
    },
    editJob(data) {
      this.confirmModal.job_id = data.job_id;
      this.confirmModal.isShowPopup = true;
    },
    modalCallBack(action) {
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        this.$router.push({
          path: `/edit-job/${this.confirmModal.job_id}?step=0`,
        });
      }
      this.confirmModal.isShowPopup = false;
    },
    async onResubmitCandidate(data) {
      this.profile = {};
      this.payload = {};
      await this.setDefaultAlertDate(data);
      this.resubmitModel.title = "Re-Submit Candidate";
      this.resubmitModel.data = data;
      this.resubmitModel.isResubmit = true;
      this.resubmitModel.isShowPopup = true;
    },
    onResubmitClicked() {
      if (!this.profile.comments) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        });
        return false;
      }
      let data = this.resubmitModel.data;
      let finalpayload = {
        candidate_uid: data.candidate_uid,
        job_id: data.job_id,
        payload: {
          ...this.payload,
          tab_id: 3,
          filter_id: 5,
          action_id: 14,
          sub_status_id: 53,
          current_status: data.status_id,
          current_sub_status: data.sub_status_id,
        },
      };
      this.updateCandidateStatus(finalpayload);
      this.resubmitModel.isResubmit = false;
      this.resubmitModel.isShowPopup = false;
    },
    onTabChangefordocuments(event) {},

    onTabChange(event) {
      this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
      this.$store.commit("SET_DASHBOARD_ACTIVE_TAB", event);
      this.activeTab = event;
      this.setDisplayTabAsFalse();
      let tabIndex = this.isCandidateSupplierFromAccessToken
        ? event + 1
        : event;
      (this.isJobSupplierFromAccessToken || this.isUKMainAgency) && tabIndex > 2
        ? (tabIndex -= 1)
        : null;
      if (
        event === 2 &&
        (this.isJobSupplierFromAccessToken || this.isUKMainAgency)
      ) {
        Vue.set(this.displayTab, "cv_request", true);
      } else {
        switch (tabIndex) {
          case 0:
            // this.fetchJobApprovalAction()
            // this.getApprovedAndCancelledJobs;
            Vue.set(this.displayTab, "job_approval", true);
            break;
          case 1:
            // this.fetchJobsForDashboard()
            Vue.set(this.displayTab, "job_expiry", true);
            break;
          case 2:
            // this.fetchSubmissionActionDetails()
            // this.getApprovedAndCancelledSubmission;
            Vue.set(this.displayTab, "submission", true);
            break;
          case 3:
            // this.fetchInterviewRequestAction()
            // this.getScheduleSlotCount;
            Vue.set(this.displayTab, "schedule", true);
            break;
          case 4:
            // this.getInterviewScheduledCandidateActionDetails()
            Vue.set(this.displayTab, "interview", true);
            break;
          case 5:
            Vue.set(this.displayTab, "offered", true);
            break;
          case 6:
            Vue.set(this.displayTab, "onboard", true);
            break;
          case 7:
            Vue.set(this.displayTab, "joining", true);
            break;
        }
      }
    },
    getSlotDateAsString(action_detail) {
      let returnString = "";
      if (action_detail.available_slot1_date) {
        returnString += `${this.formatDate(
          action_detail?.available_slot1_date
        )} (${this.formatTime(
          action_detail.available_slot1_start
        )} - ${this.formatTime(action_detail.available_slot1_end)})`;
      }
      if (action_detail.available_slot2_date) {
        returnString += `, ${this.formatDate(
          action_detail?.available_slot2_date
        )} (${this.formatTime(
          action_detail.available_slot2_start
        )} - ${this.formatTime(action_detail.available_slot2_end)})`;
      }
      if (action_detail.available_slot3_date) {
        returnString += `, ${this.formatDate(
          action_detail?.available_slot3_date
        )} (${this.formatTime(
          action_detail.available_slot3_start
        )} - ${this.formatTime(action_detail.available_slot3_end)})`;
      }
      return returnString;
    },
    getSelectedSlotDateAsString(action_detail) {
      let returnString = "";
      if (action_detail.selected_slot_date) {
        returnString += `${this.formatDate(
          action_detail?.selected_slot_date
        )} (${this.formatTime(
          action_detail.selected_slot_start
        )} - ${this.formatTime(action_detail.selected_slot_end)})`;
      }
      return returnString;
    },
    async movetoonboard(data) {
      //Restricted for Demo Customer
      if (this.getCustomerId === 8 && this.getOrgIDFromAccessToken === 7) {
        this.notificationModal.isShowPopup = true;
        return;
      }
      this.transaction_job_id = data.job_id;
      let currentpayload = {
        status_id: data.status_id,
        sub_status_id: data.sub_status_id,
      };
      this.fetchCommentsfor_recruiter(currentpayload).then((res) => {
        let comments_data = this.getActionComments;
        let values = comments_data.map((val) => ({
          label: val.comments,
          code: val.comments_type_id,
        }));
        this.comments_data = values;
      });

      let candetails = {
        customer_uid: data.customer_uid,
        candidate_uid: data.candidate_uid,
        job_id: data.job_id,
      };
      this.fetchUploadedDocumentbyCandidateId(candetails).then((res) => {
        let offerdata = this.getUploadedDocument;
      });
      if (!this.dialingCodeWithCountryCode) {
        this.initFetchContactInfoOptions();
      }
      let { sub_status_for_schedule } = this.onboard_action;
      this.fetchSubStatusReasonAction(
        this.onboard_action
        // sub_status_for_schedule
      );
      await this.setDefaultAlertDate(data);
      // this.resubmitModel.title = "Onboarding Documents";
      // this.resubmitModel.data = data;
      // this.resubmitModel.isOnboardDocument = true;
      // this.resubmitModel.isShowPopup = true;

      this.offerOnboardModal.isShowPopup = true;
      this.offerOnboardModal.title = "Onboarding Documents";
      this.offerOnboardModal.isOnBoard = true;
      this.offerOnboardModal.data = data;
      this.offerOnboardModal.buttons = ["Cancel", "Initiate Offer"];

      this.onboarddata = data;
      this.mark_action_id = data.action_detail_id;
      // this.changeStatusfromOfferToOnBoard(data)
    },
    async viewDocument_forCandidate(data) {
      this.transaction_job_id = data.job_id;
      if (!this.dialingCodeWithCountryCode) {
        this.initFetchContactInfoOptions();
      }
      let { sub_status_for_schedule } = this.onboard_action;
      this.fetchSubStatusReasonAction(
        this.onboard_action
        // sub_status_for_schedule
      );
      await this.setDefaultAlertDate(data);
      let candetails = {
        customer_uid: data.customer_uid,
        candidate_uid: data.candidate_uid,
        job_id: data.job_id,
      };
      this.fetchUploadedDocumentbyCandidateId(candetails).then((res) => {
        let offerdata = this.getUploadedDocument;
      });
      this.resubmitModel.title = "Documents";
      this.resubmitModel.data = data;
      this.resubmitModel.isViewDocs = true;
      this.resubmitModel.isShowPopup = true;
    },
    closeDocumentModal() {
      this.documentShowModal.isShowPopup = false;
      this.offerViewDoc = false;
      this.onboardViewDoc = false;
    },
    onViewDocuments(data) {
      this.transaction_job_id = data.job_id;
      let candetails = {
        customer_uid: data.customer_uid,
        candidate_uid: data.candidate_uid,
        job_id: data.job_id,
      };
      if (this.displayTab.offered) {
        this.activeTabForDoc = 0;
        this.activeOfferDocTab = 0;
      } else if (this.displayTab.onboard) {
        this.activeTabForDoc = 1;
        this.activeOnboardDocTab = 0;
      }
      this.fetchUploadedDocumentbyCandidateId(candetails).then((res) => {
        let offerdata = this.getUploadedDocument;
      });
      this.documentShowModal.isShowPopup = true;
    },
    moveToJoining(data) {
      //Restricted for Demo Customer
      if (this.getCustomerId === 8 && this.getOrgIDFromAccessToken === 7) {
        this.notificationModal.isShowPopup = true;
        return;
      }
      this.joining_data = data;
      this.transaction_job_id = data.job_id;
      this.joiningConfirmModal.modalContent =
        "Please confirm this candidate is being moved to 'Joined'";
      this.joiningConfirmModal.isShowPopup = true;
    },
    joiningConfirmModalCallBack(action, payload) {
      if (action === true && payload) {
        this.joiningConfirmModal.isShowPopup = false;
        let data = this.joining_data;
        const { joining_date } = payload;
        let finalpayload = {
          candidate_uid: data.candidate_uid,
          job_id: data.job_id,
          payload: {
            ...this.payload,
            tab_id: 4,
            filter_id: 30,
            // action_id: 18,
            sub_status_id: 63,
            // reason_id: 130,
            current_status: data.status_id,
            current_sub_status: data.sub_status_id,
            comments: "Approved for Joining",
            joining_date,
          },
        };
        this.updateCandidateStatus(finalpayload).then((res) => {
          this.showbtn = true;
          this.callForActions();
          this.hideLoader();
        });
      }
      this.joiningConfirmModal.isShowPopup = false;
    },
    resubmitConfirmModalCallBack(action) {
      if (action == "Confirm") {
        this.update_job_expiryDate({
          action_detail_id: this.jobreapproval_jobaction_id,
        }).then((res) => {
          this.fetchJobApprovalAction();
          this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
        });
        //this.callForActions();
        this.jobreapproval_jobaction_id = null;
        this.resubmitConfirmModal.isjobreapproval = false;
        this.resubmitConfirmModal.isShowPopup = false;
      }
      this.resubmitConfirmModal.isShowPopup = false;
    },
    documentUploadforOffer(data) {
      this.mark_action_id = data.action_detail_id;
      let clickeddata = {
        candidate_uid: data.candidate_id,
        customer_uid: data.customer_uid,
        user_id: data.candidate.user_id,
        job_id: data.job_id,
      };
      this.offerDocumentModel.data = clickeddata;
      this.offerDocumentModel.isShowPopup = true;
      this.currentTabfordocument = 1;
      this.onInitiateOfferDocuments(data);
    },
    documentUploadforOnBoard(data) {
      // this.mark_action_id = data.action_detail_id;
      // let clickeddata = {
      //   candidate_uid: data.candidate_id,
      //   customer_uid: data.customer_uid,
      //   user_id: data.candidate.user_id,
      //   job_id: data.job_id,
      // };
      // this.offerDocumentModel.data = clickeddata;
      // this.offerDocumentModel.isShowPopup = true;
      // this.currentTabfordocument = 1;
      this.movetoonboard(data);
    },
    markAsReviewed(data) {
      let currentpayload = {
        status_id: data.status_id,
        sub_status_id: data.sub_status_id,
      };
      this.fetchCommentsfor_recruiter(currentpayload).then((res) => {
        let comments_data = this.getActionComments;
        let values = comments_data.map((val) => ({
          label: val.comments,
          code: val.comments_type_id,
        }));
        this.comments_data = values;
      });

      if (data.status_id == 54 && data.sub_status_id == 57) {
        this.mark_action_id = data.action_detail_id;
        let clickeddata = {
          candidate_uid: data.candidate_id,
          customer_uid: data.customer_uid,
          user_id: data.candidate.user_id,
          job_id: data.job_id,
        };
        this.offerModel.data = clickeddata;
        this.offerModel.isShowPopup = true;
        this.currentTabfordocument = 1;
        this.onInitiateOffer(data);
      } else if (data.status_id == 58 && data.sub_status_id == 59) {
        let clickeddata = {
          candidate_uid: data.candidate_id,
          customer_uid: data.customer_uid,
          user_id: data.candidate.user_id,
          job_id: data.job_id,
        };
        this.offerModel.data = clickeddata;
        this.offerModel.isShowPopup = true;
        this.currentTabfordocument = 1;
        this.onInitiateOffer(data);
      } else {
        let payload = {
          action_detail_id: data.action_detail_id,
        };

        this.markAsCompleted(payload).then((res) => {
          // this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
          this.callForActions();
        });
      }
    },
    JobReapprovalstateClicked(data) {
      this.resubmitConfirmModal.data = data;
      this.resubmitConfirmModal.isjobreapproval = true;
      this.resubmitConfirmModal.isShowPopup = true;
      this.jobreapproval_jobaction_id = data.action_detail_id;
    },
    // onJobreapprovalClicked() {
    //   this.update_job_expiryDate(this.jobreapproval_jobaction_id).then(
    //     (res) => {
    //       this.fetchJobApprovalAction();
    //       this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
    //     }
    //   );
    //   //this.callForActions();
    //   this.jobreapproval_jobaction_id = null;
    //   this.resubmitModel.isjobreapproval = false;
    //   this.resubmitModel.isShowPopup = false;
    // },
    navigateToJobBoard(data) {
      this.$router.push(`/jobs-list/status/active?job_id=${data?.job_id}`);
    },
    fetchSubStatusReasonAction(action_data, sub_status_id = null) {
      this.profile = {};
      this.payload = {};
      const { action_id, label, filter_id, tab_id } = action_data;
      this.profile["action_id"] = { label, code: action_id };
      this.fetchSubStatusOptions({
        tab_id,
        filter_id,
        action_id,
      }).then((res) => {
        if (this.getSubStatusOptions.length === 1 && !sub_status_id) {
          this.handleSelect(
            "sub_status_id",
            this.getSubStatusOptions[0],
            action_data
          );
        } else if (sub_status_id) {
          this.handleSelect("sub_status_id", sub_status_id, action_data);
        }
      });
      this.fetchJobCandidateReasonCode({ tab_id, filter_id, action_id });
    },
    async onReinstateClicked(data) {
      await this.setDefaultAlertDate(data);
      this.fetchSubStatusReasonAction(this.reinstate_action);
      this.resubmitModel.title = "Re-Submit Profile for Review";
      this.resubmitModel.data = data;
      this.resubmitModel.isReinstate = true;
      this.resubmitModel.isShowPopup = true;
    },
    async onVaultstateClicked(data) {
      this.onvaultdata = data;
      //this.fetchSubStatusReasonAction(this.reinstate_action);
      //await this.setDefaultAlertDate();
      this.resubmitModel.title = "Vault Candidate";
      this.resubmitModel.data = data;
      this.resubmitModel.isVault = true;
      this.resubmitModel.isShowPopup = true;
    },
    onVaultconfirmModalmodalCallBack(action) {},
    onReinstateSubmit() {
      const { action_id, filter_id, tab_id } = this.reinstate_action;
      const {
        status_id: current_status,
        sub_status_id: current_sub_status,
        candidate_uid,
        job_id,
      } = this.resubmitModel.data;
      this.payload = {
        ...this.payload,
        action_id,
        filter_id,
        tab_id,
        current_status,
        current_sub_status,
      };
      const deleteFields = ["expiry_date", "priority_level"];
      deleteFields.forEach((element) => {
        delete this.payload[element];
      });
      // if (!this.profile.reason_id) {
      //   this.showToast({
      //     class: "bg-danger text-white",
      //     message: "Please provide reason!",
      //   });
      //   return false;
      // }
      if (!this.profile.comments) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        });
        return false;
      }
      let finalPayload = {
        candidate_uid: candidate_uid,
        job_id: job_id,
        payload: {
          ...this.payload,
        },
      };
      this.updateCandidateStatus(finalPayload);
      this.resubmitModel.isReinstate = false;
      this.resubmitModel.isShowPopup = false;
    },
    async onRequestInterview(data) {
      let currentpayload = {
        status_id: data.status_id,
        sub_status_id: 51,
      };
      this.fetchCommentsfor_recruiter(currentpayload).then((res) => {
        let comments_data = this.getActionComments;
        let values = comments_data.map((val) => ({
          label: val.comments,
          code: val.comments_type_id,
        }));
        this.comments_data = values;
      });
      let { sub_status_for_request } = this.interview_action;
      this.fetchSubStatusReasonAction(
        this.interview_action,
        sub_status_for_request
      );
      await this.setDefaultAlertDate(data);
      this.resubmitModel.title = "Interview - Scheduling Request";
      this.resubmitModel.data = data;
      this.resubmitModel.isReqInterview = true;
      this.resubmitModel.isShowPopup = true;
      this.comments_data = {};
    },
    onRequestInterviewSubmit() {
      const { action_id, filter_id, tab_id } = this.interview_action;
      const {
        status_id: current_status,
        sub_status_id: current_sub_status,
        job_id,
        candidate_uid,
      } = this.resubmitModel.data;
      this.payload = {
        ...this.payload,
        action_id,
        filter_id,
        tab_id,
        current_status,
        current_sub_status,
      };
      // if (!this.profile.reason_id) {
      //   this.showToast({
      //     class: "bg-danger text-white",
      //     message: "Please provide reason!",
      //   });
      //   return false;
      // }
      if (!this.profile.type_of_process) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please specify the process type!",
        });
        return false;
      }
      if (!this.profile.interview_mode) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please specify the mode of contact!",
        });
        return false;
      }
      if (!this.profile.alloted_time) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please specify the duration!",
        });
        return false;
      }
      if (!this.profile.comments) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        });
        return false;
      }
      let finalPayload = {
        candidate_uid: candidate_uid,
        job_id: job_id,
        payload: {
          ...this.payload,
        },
      };
      this.updateCandidateStatus(finalPayload);
      this.resubmitModel.isReqInterview = false;
      this.resubmitModel.isShowPopup = false;
    },
    async onInitiateOffer(data) {
      /* ---------------------------------------------------------------- */
      let candetails = {
        customer_uid: this.offerModel.data.customer_uid,
        candidate_uid: this.offerModel.data.candidate_uid,
        job_id: data.job_id,
      };
      this.offerconfimrbtnjob = data.job_id;
      this.fetchUploadedDocumentbyCandidateId(candetails).then((res) => {
        let offerdata = this.getUploadedDocument;
      });

      let job_id = data?.job_id || null;

      if (job_id) this.fetchJobDocumentsByJobID(job_id);
      let offerDetails = {
        job_id: job_id,
        candidate_uid: candetails.candidate_uid,
      };

      this.getOffersForCandidate(offerDetails).then((res) => {
        let offerdata = this.getOfferDetailsforCandidate;
        //offerdata.map(res=>{
        if (offerdata.length != 0) {
          this.showbtn = false;
        } else {
          this.showbtn = true;
        }
      });

      /* ---------------------------------------------------------------- */
      if (!this.dialingCodeWithCountryCode) {
        this.initFetchContactInfoOptions();
      }
      let { sub_status_for_schedule } = this.offer_action;
      this.fetchSubStatusReasonAction(
        this.interview_action,
        sub_status_for_schedule
      );
      await this.setDefaultAlertDate(data);
      // this.resubmitModel.title = "Create Offer";
      // this.resubmitModel.data = data;
      // this.resubmitModel.isOfferedstate = true;
      // this.resubmitModel.isShowPopup = true;
      this.offerOnboardModal.isShowPopup = true;
      this.offerOnboardModal.title = "Initiate Offer";
      this.offerOnboardModal.isOnBoard = false;
      this.offerOnboardModal.isOffer = true;
      this.offerOnboardModal.data = data;
      this.offerOnboardModal.buttons = ["Cancel", "Initiate Offer"];
    },
    async onInitiateOfferDocuments(data) {
      /* ---------------------------------------------------------------- */
      let candetails = {
        customer_uid: this.offerDocumentModel.data.customer_uid,
        candidate_uid: this.offerDocumentModel.data.candidate_uid,
        job_id: data.job_id,
      };
      this.offerconfimrbtnjob = data.job_id;
      this.fetchUploadedDocumentbyCandidateId(candetails).then((res) => {
        let offerdata = this.getUploadedDocument;
      });

      let job_id = data?.job_id || null;

      if (job_id) this.fetchJobDocumentsByJobID(job_id);
      let offerDetails = {
        job_id: job_id,
        candidate_uid: candetails.candidate_uid,
      };

      this.getOffersForCandidate(offerDetails).then((res) => {
        let offerdata = this.getOfferDetailsforCandidate;
        //offerdata.map(res=>{
        if (offerdata.length != 0) {
          this.showbtn = false;
        } else {
          this.showbtn = true;
        }
      });

      /* ---------------------------------------------------------------- */

      await this.setDefaultAlertDate(data);
      // this.resubmitModel.title = "Documents Upload";
      // this.resubmitModel.data = data;
      // this.resubmitModel.isOfferDocument = true;
      // this.resubmitModel.isShowPopup = true;

      this.offerOnboardModal.isShowPopup = true;
      this.offerOnboardModal.title = "Document Upload";
      this.offerOnboardModal.isOffer = true;
      this.offerOnboardModal.isOnBoard = false;
      this.offerOnboardModal.data = data;
      this.offerOnboardModal.buttons = ["Cancel", "Initiate Offer"];
    },

    handleChangeSelect_fordocument(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        // [name]: value ? value.id || value.code || value : null,
        [name]: value ? value.id || value.code || value : null,
        documenttitle_type_id: value.documenttitle_type_id
          ? value.documenttitle_type_id
          : null,
      };
      this.documenttitle_type_id = value.documenttitle_type_id
        ? value.documenttitle_type_id
        : null;
    },
    selectedFile(event) {
      this.isFileError = false;
      this.isFileTpeValid = false;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            // this.cropperModal.fileData = file;
            // this.cropperModal.isShowPopup = true;
            this.isImageSelected = true;
            this.fileData = file;
          } else {
            this.handleFileUploads(file);
          }
        } else {
          this.isFileTpeValid = true;
          this.FileTypeError = this.arraytoComma(fileTypeString);
          this.$refs.fileref.value = "";
          this.showToaster();
        }
      } else {
        this.$refs.fileref.value = "";
        this.isFileError = true;
        this.showToaster();
      }
    },
    handleFileUploads(event) {
      this.payload = {
        ...this.payload,
        name: ["file"],
        value: event,
        isPhotoUpload: false,
        job_id: this.offerModel.data?.job_id,
      };
      this.upload(this.payload);
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        this.payload = {
          ...this.payload,
          isPhotoUpload: true,
          name: ["file"],
          value: fileBlob,
          document_name: fileName,
        };
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        // this.$emit("imgUpdated", true);
        this.upload(this.payload);
      }
    },
    upload(payload) {
      if (
        this.resubmitModel.isOfferedstate ||
        this.resubmitModel.isOfferDocument
      ) {
        const document_type = this.payload.document_type || 27;
        const { customer_uid, candidate_uid, user_id } =
          this.offerModel.data != null
            ? this.offerModel.data
            : this.offerDocumentModel.data;

        if (payload && payload.name) {
          if (payload.isPhotoUpload) {
            this.uploadPhoto({
              customer_uid,
              candidate_uid,
              user_id,
              [payload.name]: payload.value,
              document_type,
              documenttitle_type_id: this.documenttitle_type_id || 1,
              ["fileName"]: payload.document_name,
              job_id:
                this.offerModel.data != null
                  ? this.offerModel.data.job_id
                  : this.offerDocumentModel.data.job_id,
              comments: this.uploadData.comments_dtype,
            }).then((res) => {
              this.afteruploaded();
            });
          } else {
            this.uploadResume({
              customer_uid,
              candidate_uid,
              user_id,
              [payload.name]: payload.value,
              document_type,
              job_id:
                this.offerModel.data != null
                  ? this.offerModel.data.job_id
                  : this.offerDocumentModel.data.job_id,
              comments: this.uploadData.comments_dtype,
            }).then((res) => {
              this.afteruploaded();
            });
          }
        }
      } else {
        const document_type = this.payload.document_type || 25;
        const { customer_uid, candidate_uid } = this.onboarddata;
        const { user_id } = this.onboarddata.candidate;
        if (payload && payload.name) {
          if (payload.isPhotoUpload) {
            this.uploadPhoto({
              customer_uid,
              candidate_uid,
              user_id,
              [payload.name]: payload.value,
              document_type,
              documenttitle_type_id: this.documenttitle_type_id || 1,
              ["fileName"]: payload.document_name,
              job_id: this.onboarddata?.job_id,
              comments: this.uploadData.comments_dtype,
            }).then((res) => {
              this.afteruploaded();
            });
          } else {
            this.uploadResume({
              customer_uid,
              candidate_uid,
              user_id,
              [payload.name]: payload.value,
              document_type,
              job_id: this.onboarddata?.job_id,
              comments: this.uploadData.comments_dtype,
            }).then((res) => {
              this.afteruploaded();
            });
          }
        }
      }
      this.$refs.comments_dtype.value = null;
      this.$refs.DocumentInfo.reset();
      this.$refs.DocumentInfo.errors.clear;
    },
    afteruploaded() {
      if (
        this.resubmitModel.isOfferedstate ||
        this.resubmitModel.isOfferDocument
      ) {
        //this.$refs.fileref.value = "";
        this.uploadData = {};
        //this.fetchUploadedDocument();
        let candetails = {
          customer_uid:
            this.offerModel.data != null
              ? this.offerModel.data.customer_uid
              : this.offerDocumentModel.data.customer_uid,
          candidate_uid:
            this.offerModel.data != null
              ? this.offerModel.data.candidate_uid
              : this.offerDocumentModel.data.candidate_uid,
        };
        this.fetchUploadedDocumentbyCandidateId(candetails);
        let jobId =
          this.offerModel.data != null
            ? this.offerModel.data.job_id
            : this.offerDocumentModel.data.job_id;
        let job_id = jobId || null;

        if (job_id) this.fetchJobDocumentsByJobID(job_id);
        let offerDetails = {
          job_id: job_id,
          candidate_uid: candetails.candidate_uid,
        };
        this.fileData1 = {};
        this.isImageSelected = false;
      } else {
        this.uploadData = {};
        //this.fetchUploadedDocument();
        let candetails = {
          customer_uid: this.onboarddata.customer_uid,
          candidate_uid: this.onboarddata.candidate_uid,
        };
        this.fetchUploadedDocumentbyCandidateId(candetails);
        let jobId = this.onboarddata.job_id;
        let job_id = jobId || null;

        if (job_id) this.fetchJobDocumentsByJobID(job_id);
        let offerDetails = {
          job_id: job_id,
          candidate_uid: candetails.candidate_uid,
        };
        this.fileData1 = {};
        this.isImageSelected = false;
      }
    },
    fileDownload(data, index) {
      this.disabledButton.push(index);
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name }).finally(() => {
        this.disabledButton.shift();
      });
      return false;
    },
    preview(data) {
      if (
        data.document_ext === "PNG" ||
        data.document_ext === "JPG" ||
        data.document_ext === "JPEG" ||
        data.document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = "https://" + window.location.host + data.link;
        //this.iframe.src = "http://localhost" + data.link;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          "https://" + window.location.host + data.link
        );
        /*this.iframe.pdf.src = pdf.createLoadingTask(
          "http://localhost" + data.link
        );*/
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (
        data.document_ext === "TXT" ||
        data.document_ext === "EPS" ||
        data.document_ext === "TIF" ||
        data.document_ext === "TIFF" ||
        data.document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.confirmationModal.isPreview = false;
        this.confirmationModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=https://" +
          window.location.host +
          data.link +
          "";
        /*  this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=http://localhost" +
          data.link +
          "";*/
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      }
    },

    closeModal() {
      this.$refs.fileref.value = "";
      this.uploadData = {};
      this.fileData = {};
      this.isImageSelected = false;
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    arraytoComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    showToaster() {
      setTimeout(() => {
        this.isFileError = false;
        this.isFileTpeValid = false;
      }, 5000);
    },
    deleteDocuments(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document ${
        data.name ? `- ${data.name}` : ""
      } ?`;
      this.candidate_document_id = data.document_id;
      this.deleteConfirmModal.isShowPopup = true;
      this.candidate_document = data;
    },
    /* deletemodalCallBack_document(action) {
      if (action === "Confirm") {
        //this.deleteDocument(this.candidate_document_id);
        let candetails = {
          customer_uid:
            this.offerModel.data != null
              ? this.offerModel.data.customer_uid
              : this.offerDocumentModel.data.customer_uid,
          candidate_uid:
            this.offerModel.data != null
              ? this.offerModel.data.candidate_uid
              : this.offerDocumentModel.data.candidate_uid,
          candidate_document_id: this.candidate_document_id,
        };

        this.deleteDocumentByCandidateId(candetails);
        candetails = {
          customer_uid:
            this.offerModel.data != null
              ? this.offerModel.data.customer_uid
              : this.offerDocumentModel.data.customer_uid,
          candidate_uid:
            this.offerModel.data != null
              ? this.offerModel.data.candidate_uid
              : this.offerDocumentModel.data.candidate_uid,
        };

        // this.fetchUploadedDocumentbyCandidateId(candetails);
      }
      this.candidate_document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },*/
    deletemodalCallBack_document(action) {
      if (action === "Confirm") {
        let candetails = {
          customer_uid: this.resubmitModel.data.customer_uid,
          candidate_uid: this.resubmitModel.data.candidate_id,
          candidate_document_id: this.candidate_document_id,
        };
        if (!this.candidate_document.org_type) {
          this.deleteDocumentByCandidateId(candetails);
          candetails = {
            customer_uid: this.offerModel.data.customer_uid,
            candidate_uid: this.offerModel.data.candidate_id,
          };
        } else {
          let candetails = {
            customer_uid: this.resubmitModel.data.customer_uid,
            candidate_uid: this.resubmitModel.data.candidate_id,
            document_id: this.candidate_document_id,
            job_id: this.resubmitModel?.job_id,
          };
          this.deleteCandidaterecord(candetails);
        }
      }

      this.candidate_document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },

    modalCallBack_preview(action) {
      this.confirmationModal.isPreview = false;
      this.confirmationModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    async onInterviewSchedule(data) {
      let currentpayload = {
        status_id: data.status_id,
        sub_status_id: 55,
      };
      this.fetchCommentsfor_recruiter(currentpayload).then((res) => {
        let comments_data = this.getActionComments;
        let values = comments_data.map((val) => ({
          label: val.comments,
          code: val.comments_type_id,
        }));
        this.comments_data = values;
      });
      if (!this.dialingCodeWithCountryCode) {
        this.initFetchContactInfoOptions();
      }
      let { sub_status_for_schedule } = this.interview_action;
      this.fetchSubStatusReasonAction(
        this.interview_action,
        sub_status_for_schedule
      );
      await this.setDefaultAlertDate(data);
      this.resubmitModel.title = "Confirm – Candidate Availability";
      this.resubmitModel.data = data;
      this.resubmitModel.isInterviewSlot = true;
      this.resubmitModel.isShowPopup = true;
      if (data?.interview_mode === "call")
        this.handleInput("contact_phone", data?.candidate?.phone);
    },
    async openEmailModal(data) {
      if (
        this.currentUserRole === this.Role.systemCandidate ||
        this.currentUserRole === this.Role.customerCandidate
      ) {
        return;
      }
      this.emailId = data.candidate.email;
      this.emailModal.email = true;
      this.emailModal.subject = `Interview Schedule / Slot Confirmation for Job  - ${data?.job_display_uid} | ${data?.job?.job_title}`;
      this.emailModal.message = `<p>Dear ${
        data?.candidate_name
      }<p/><p><br></p><strong>The slots for  ${
        data?.job?.job_title
      } Interview:</strong>${this.getSlot(
        this.slots
      )}\n<strong>Interviewer Name: </strong>${
        data?.initiated_by_user_name
      } - ${data?.initiated_by_user?.email}`;
      this.emailModal.candidate_uid = data.candidate_uid;
    },
    modalCallBackEmail(columnKey) {
      this.emailModal[columnKey] = false;
    },
    conversationModalCallBack(delete_id, subject) {
      this.deleteModal.isShowPopup = true;
      this.emailModal.email = false;
      this.deleteModal.delete_id = delete_id;
      this.deleteModal.subject = subject;
    },
    deleteModalCallBack() {
      this.deleteModal.isShowPopup = false;
      this.emailModal.email = true;
    },

    async onInterviewSlotSubmit() {
      let { interview_mode } = this.resubmitModel.data;
      const { action_id, filter_id, tab_id } = this.interview_action;
      const {
        status_id: current_status,
        job_id,
        candidate_uid,
      } = this.resubmitModel.data;
      this.payload = {
        ...this.payload,
        action_id,
        filter_id,
        tab_id,
        current_status,
        current_sub_status: 51,
      };
      if (
        !(
          this.payload["action_detail_id"] && this.payload["selected_slot_date"]
        )
      ) {
        let errorMsg = this.slots.length
          ? "Please select any one slot"
          : "please wait for slot schedule";
        this.showToast({
          class: "bg-danger text-white",
          message: errorMsg,
        });
        return false;
      }
      if (interview_mode == "video" && !this.profile.interview_link) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide interview link!",
        });
        return false;
      }
      if (interview_mode == "call" && !this.profile.contact_phone) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide contact number!",
        });
        return false;
      }
      if (!this.profile.comments) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        });
        return false;
      }
      const external_interviewer_ids =
        await this.$refs?.addExtraInterviewer?.externalUser();
      if (external_interviewer_ids) {
        this.payload = {
          ...this.payload,
          external_interviewer_ids,
        };
        let finalPayload = {
          candidate_uid: candidate_uid,
          job_id: job_id,
          payload: {
            ...this.payload,
          },
        };
        this.updateCandidateStatus(finalPayload);
        this.resubmitModel.isInterviewSlot = false;
        this.resubmitModel.isShowPopup = false;
        return;
      }
      return;
    },

    onVaultSubmit() {
      let payload = {
        action_detail_id: this.onvaultdata.action_detail_id,
      };
      this.markAsCompleted(payload).then((res) => {
        this.callForActions();
      });
      this.resubmitModel.isVault = false;
      this.resubmitModel.isShowPopup = false;
    },
    onOnboardSubmit() {
      const values = Array.from(
        document.querySelectorAll('input[type="checkbox"]')
      )
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value);
      if (values.length == 0) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Select any one actionable document!",
        });
        return false;
      }
      if (this.profile.comments == null) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        });
        return false;
      }
      this.onboardConfirmModal.modalContent = `Please confirm you are ready to send the onboarding documents`;
      this.onboardConfirmModal.isShowPopup = true;
    },
    onboardConfirmModalCallBack(action) {
      if (action == "Confirm") {
        const values = Array.from(
          document.querySelectorAll('input[type="checkbox"]')
        )
          .filter((checkbox) => checkbox.checked)
          .map((checkbox) => checkbox.value);
        let job_id, offer_id;
        this.fetchOffer.map((res) => {
          job_id = res.job_id;
          offer_id = res.offer_id;
        });
        let payload1 = {
          is_accepted: false,
          offer_id: offer_id,
          job_id: job_id,
          organisation_document_ids: values,
        };

        this.create_Onboard(payload1);
        this.showbtn = false;
        this.changeStatusfromOfferToOnBoard();
      }
      this.resubmitModel.isOnboardDocument = false;
      this.resubmitModel.isShowPopup = false;
      this.onboardConfirmModal.isShowPopup = false;
      this.onboardConfirmModal.isShowPopup = false;
    },
    onDocumentSubmit() {
      /*const values = Array
      .from(document.querySelectorAll('input[type="checkbox"]'))
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
      if(values.length != 1){
         this.showToast({
          class: "bg-danger text-white",
          message: "Select any one actionable document!",
        })
        return false
      } */
      if (this.profile.comments == null) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        });
        return false;
      }
      this.offerDocumentConfirmModal.modalContent = `Are you sure to submit this Document ?`;
      this.offerDocumentConfirmModal.isShowPopup = true;
    },
    onOfferedSubmit() {
      const values = Array.from(
        document.querySelectorAll('input[type="checkbox"]')
      )
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value);
      if (!values.length) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Select atleast any one actionable document!",
        });
        return false;
      }
      if (this.profile.comments == null) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        });
        return false;
      }
      this.offerConfirmModal.modalContent = `Please confirm you are ready to send these documents`;
      this.offerConfirmModal.isShowPopup = true;
    },
    offerDocumentConfirmModalCallBack(action) {
      if (action == "Confirm") {
        if (this.getUploadedDocument.length != 0) {
          let uploaded_ids_for_offer = this.getUploadedDocument?.length
            ? _(this.getUploadedDocument)
                .filter(({ job_id }) => job_id === this.offerconfimrbtnjob)
                .map(({ candidate_document_id }) => candidate_document_id)
                .value()
            : [];
          let payload1 = {
            status: false,
            candidate_uid:
              this.offerModel.data != null
                ? this.offerModel.data.candidate_uid
                : this.offerDocumentModel.data.candidate_uid,
            customer_uid:
              this.offerModel.data != null
                ? this.offerModel.data.customer_uid
                : this.offerDocumentModel.data.customer_uid,
            job_id: this.offerconfimrbtnjob,
            organisation_document_ids: uploaded_ids_for_offer,
          };
          this.create_offer(payload1);
          this.showbtn = false;
          this.showToast({
            class: "bg-success text-white",
            message: "Documents sent to Candidate!",
          }).then((res) => {
            this.showbtn = true;
            this.callForActions();
            this.hideLoader();
          });
        }
      }

      this.resubmitModel.isOfferedstate = false;
      this.offerDocumentConfirmModal.isShowPopup = false;
      this.resubmitModel.isShowPopup = false;
    },
    offerConfirmModalCallBack(action) {
      if (action == "Confirm") {
        if (this.getUploadedDocument.length != 0) {
          let uploaded_ids_for_offer = this.getUploadedDocument?.length
            ? _(this.getUploadedDocument)
                .filter(({ job_id }) => job_id === this.offerconfimrbtnjob)
                .map(({ candidate_document_id }) => candidate_document_id)
                .value()
            : [];
          let payload1 = {
            status: false,
            candidate_uid: this.offerModel.data.candidate_uid,
            customer_uid: this.offerModel.data.customer_uid,
            job_id: this.offerconfimrbtnjob,
            organisation_document_ids: uploaded_ids_for_offer,
          };
          this.create_offer(payload1);
          this.showbtn = false;
          this.showToast({
            class: "bg-success text-white",
            message: "Offer sent to Candidate!",
          }).then((res) => {
            let tempdata = {
              candidate_uid: this.offerModel.data.candidate_uid,
              job_id: this.offerconfimrbtnjob,
              action_detail_id: this.mark_action_id,
              comments: this.uploadData.comments_dtype,
            };
            this.changeStatusforOffer(tempdata);
          });
        }
      } else {
        /*this.showToast({
          class: "bg-danger text-white",
          message: "Select the files.",
        });*/
      }
      this.resubmitModel.isOfferedstate = false;
      this.offerConfirmModal.isShowPopup = false;
      this.resubmitModel.isShowPopup = false;
      this.onJobTab = false;
    },
    changeStatusfromOfferToOnBoard() {
      let data = this.onboarddata;

      let finalpayload = {
        candidate_uid: data.candidate_uid,
        job_id: data.job_id,
        payload: {
          ...this.payload,
          tab_id: 4,
          filter_id: 29,
          action_id: 8,
          sub_status_id: 69,
          current_status: data.status_id,
          current_sub_status: data.sub_status_id,
          //  comments: "Approved For Onboarding",
          reason_id: 130,
        },
      };
      // if (action && payload) {
      const { job_id } = data.job_id;
      const candidate_uid = data.candidate_uid;

      if (!this.dialingCodeWithCountryCode) {
        this.initFetchContactInfoOptions();
      }
      // let { sub_status_for_schedule } = this.offer_action;

      this.fetchSubStatusReasonAction(
        this.onboard_action
        // sub_status_for_schedule
      );

      this.initUpdateCandidateStatus(finalpayload).then((res) => {
        this.callForActions();
        // this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
        this.hideLoader();
      });
      let payload = {
        action_detail_id: data.action_detail_id,
      };
      this.markAsCompleted(payload).then((res) => {
        // this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
        this.callForActions();
      });
    },
    changeStatusforOffer(data) {
      let finalpayload = {
        candidate_uid: data.candidate_uid,
        job_id: data.job_id,
        payload: {
          ...this.payload,
          tab_id: 4,
          filter_id: 28, //24
          action_id: 7,
          sub_status_id: 59, //63
          current_status: data.status_id,
          current_sub_status: data.sub_status_id,
          // comments: "Awaiting Offer",
          reason_id: 130,
        },
      };
      // if (action && payload) {
      const { job_id } = data.job_id;
      const candidate_uid = data.candidate_uid;

      if (!this.dialingCodeWithCountryCode) {
        this.initFetchContactInfoOptions();
      }
      // let { sub_status_for_schedule } = this.offer_action;
      this.fetchSubStatusReasonAction(
        this.offer_action
        // sub_status_for_schedule
      );
      // console.log("sub:",sub_status_for_schedule)

      /*  let finalpayload = {
        candidate_uid: data.candidate_uid,
        job_id: data.job_id,
        payload: {
          //...this.payload,
          tab_id: 4,
          filter_id: 28,
          action_id: 7,
          sub_status_id: 59,
          current_status: data.status_id,
          current_sub_status: data.sub_status_id,
          comments: "Awaiting Offer",
          reason_id: 130,
        },
      };*/
      //const appentAction = this.initUpdateCandidateStatus(  finalpayload );
      this.initUpdateCandidateStatus(finalpayload).then((res) => {
        this.callForActions();
        // this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
        this.hideLoader();
      });

      let payload = {
        action_detail_id: data.action_detail_id,
      };
      this.markAsCompleted(payload).then((res) => {
        // this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
        this.callForActions();
      });
    },
    handleRadio(slot) {
      const { action_detail_id, date, start_time, end_time } = slot;
      this.payload = {
        ...this.payload,
        action_detail_id,
        selected_slot_date: date,
        selected_slot_start: start_time,
        selected_slot_end: end_time,
      };
      this.handleDateChange("expiry_date", moment(date).toDate());
    },
    updateCandidateStatus(payload) {
      return this.initUpdateCandidateStatus(payload).then((res) => {
        this.callForActions();
        // this.$store.commit("SET_DASHBOARD_COLLAPSE_INDEX", null);
        this.hideLoader();
      });
    },
    setDefaultAlertDate(data) {
      let alertDate = moment().add(7, "d").toDate();
      this.handleDateChange("expiry_date", alertDate);
      // this.handleSelect("priority_level", { code: 1, label: 1 });
      this.handleChangeRadio("alert_days", 7);
      this.handleChangeRadio("is_urgent", false);

      const { job_id, candidate_uid, candidate_id } = data;
      this.fetchCandidateJoiningDate({
        order_by: "-action_detail_id",
        date_field: "initiated_date",
        completed_by__isnull: true,
        skip: 0,
        limit: 1,
        action_id: 18,
        job_id,
        candidate_uid: candidate_uid || candidate_id,
      }).then((res) => {
        if (res?.status === 200) {
          if (res?.data?.length) {
            const joining_date = res?.data[0]?.joining_date;
            if (joining_date) {
              const joining_date_obj = moment(joining_date).toDate();
              this.handleDateChange("joining_date", joining_date_obj);
            }
          }
        }
      });
      return true;
    },
    setDisplayTabAsFalse() {
      for (let [key, value] of Object.entries(this.displayTab)) {
        Vue.set(this.displayTab, key, false);
      }
    },
    offerOnboardModalCallBack(action, payload) {
      if (action && payload) {
        const { documentPayload, processPayload } = payload;
        let appendAction = [];
        this.offerOnboardModal.isShowPopup = false;
        documentPayload.forEach((element) => {
          appendAction = [...appendAction, this.createDocumentAction(element)];
        });
        Promise.all(appendAction).then(async (res) => {
          await this.initUpdateJobStatus(processPayload);
          this.offerOnboardModal.isShowPopup = false;
          this.callForActions();
        });
      } else {
        this.offerOnboardModal.isShowPopup = false;
        this.offerOnboardModal.isOffer = false;
        this.offerOnboardModal.isOnBoard = false;
        this.offerOnboardModal.isDocsView = false;
      }
    },
    onViewPreOfferDocuments(item) {
      const data = {
        candidate_uid: item.candidate_id,
        job_id: item.job_id,
        status_id: item.status_id,
        sub_status_id: item.sub_status_id,
        action_detail_id: item.action_detail_id,
        organisation_id: item.organisation_id,
      };
      const { candidate, job } = item;
      this.offerOnboardModal.isShowPopup = true;
      this.offerOnboardModal.title = "Pre-Offer Documents";
      this.offerOnboardModal.isOffer = false;
      this.offerOnboardModal.data = data;
      this.offerOnboardModal.candidate = candidate;
      this.offerOnboardModal.job = job;
      this.offerOnboardModal.isDocsView = true;
      this.offerOnboardModal.buttons = ["Close"];
    },
    onViewOfferDocuments(item) {
      const data = {
        candidate_uid: item.candidate_id,
        job_id: item.job_id,
        status_id: item.status_id,
        sub_status_id: item.sub_status_id,
        action_detail_id: item.action_detail_id,
        organisation_id: item.organisation_id,
      };
      this.offerOnboardModal.isShowPopup = true;
      this.offerOnboardModal.title = "Offer Documents";
      this.offerOnboardModal.isOffer = true;
      this.offerOnboardModal.data = data;
      this.offerOnboardModal.isDocsView = true;
      this.offerOnboardModal.buttons = ["Close"];
    },
    onViewOnboardDocuments(item) {
      const data = {
        candidate_uid: item.candidate_id,
        job_id: item.job_id,
        status_id: item.status_id,
        sub_status_id: item.sub_status_id,
        action_detail_id: item.action_detail_id,
        organisation_id: item.organisation_id,
      };
      this.offerOnboardModal.isShowPopup = true;
      this.offerOnboardModal.title = "Onboard Documents";
      this.offerOnboardModal.isOnBoard = true;
      this.offerOnboardModal.data = data;
      this.offerOnboardModal.isDocsView = true;
      this.offerOnboardModal.buttons = ["Close"];
    },
    joiningViewDocumentDetails(item) {
      const data = {
        candidate_uid: item.candidate_id || item?.candidate_uid,
        job_id: item.job_id,
        status_id: item.status_id,
        sub_status_id: item.sub_status_id,
        action_detail_id: item.action_detail_id,
        organisation_id: item.organisation_id,
      };
      this.offerOnboardModal.isShowPopup = true;
      this.offerOnboardModal.title = "Documents";
      this.offerOnboardModal.isOnBoard = true;
      this.offerOnboardModal.data = data;
      this.offerOnboardModal.isDocsView = true;
      this.offerOnboardModal.buttons = ["Close"];
    },
    getSlot(data) {
      if (data?.length) {
        let list = "";
        data.forEach((item) => {
          list += `<li><strong>${item.name}</strong> - Date: ${moment(
            item.date
          ).format("DD MMM YYYY")}, Time: ${this.formatTime(
            item.start_time
          )} to ${this.formatTime(item.end_time)}</li>`;
        });
        return `<ul>${list}</ul>`;
      }
      return "";
    },
    openCandidateInfoModal(candidate_uid) {
      if (candidate_uid) {
        this.candidateInfoModal.candidate_uid = candidate_uid;
        this.candidateInfoModal.isShowPopup = true;
      }
    },
    candidateInfoModalCallBack(action) {
      this.candidateInfoModal.isShowPopup = false;
    },
    openJobInfoModal(job_id) {
      if (job_id) {
        this.jobInfoModal.job_id = job_id;
        this.jobInfoModal.isShowPopup = true;
      }
    },
    jobInfoModalCallBack(action) {
      this.jobInfoModal.isShowPopup = false;
    },
    openInterviewFeedback(action_detail) {
      const { action_detail_id, candidate_uid, job_id } = action_detail;
      const payload = {
        // action_detail_ids__overlap: `{${action_detail_id}}`,
        candidate_uid,
        job_id,
        order_by: "-feedback_id",
        limit: 1,
      };
      this.fetchInterviewFeedBack(payload).then((res) => {
        if (res.status === 200 && this.interviewFeedBack.length) {
          this.interviewFeedBackModal.data = this.interviewFeedBack[0];
          this.interviewFeedBackModal.isShowPopup = true;
        } else {
          this.showToast({
            class: "bg-danger text-white",
            message: "No Interview Evaluation Data Found.",
          });
        }
      });
    },
    interviewFeedBackModalCallBack(action) {
      this.interviewFeedBackModal.isShowPopup = false;
    },
    notificationModalCallBack(action) {
      this.notificationModal.isShowPopup = false;
    },
  },
  filters: {
    getJobDisplayID(data) {
      if (data && Array.isArray(data))
        return data.map(
          ({ display_uid, job_title }) => `${job_title} | ${display_uid}`
        );
      else if (data && isObject(data))
        return `${data?.job_title} | ${data?.display_uid}`;
      return "--";
    },
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      return "--";
    },
    formatTime(date) {
      if (date) {
        const dateObj = new Date();
        const dateSplit = date.split(":");
        dateObj.setHours(dateSplit[0], dateSplit[1], dateSplit[2]);
        return moment(dateObj).format("hh:mm A");
      }
      return "--";
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.uploadData = {};
        let candetails = null;
        if (
          this.resubmitModel.isOfferedstate ||
          this.resubmitModel.isOfferDocument
        ) {
          candetails = {
            customer_uid:
              this.offerModel.data != null
                ? this.offerModel.data.customer_uid
                : this.offerDocumentModel.data.customer_uid,
            candidate_uid:
              this.offerModel.data != null
                ? this.offerModel.data.candidate_uid
                : this.offerDocumentModel.data.candidate_uid,
          };
        } else {
          candetails = {
            customer_uid: this.onboarddata.customer_uid,
            candidate_uid: this.onboarddata.candidate_uid,
          };
        }

        this.fetchUploadedDocumentbyCandidateId(candetails);

        let job_id = this.offerModel.data?.job_id || null;
        if (job_id) this.fetchJobDocumentsByJobID(job_id);

        this.fileData1 = {};
        this.isImageSelected = false;
      }
    },
    "$route.query": function (query) {
      let CurrentTab =
        (query.tab ? parseInt(query.tab) : this.getActiveDashboardTab) || 0;
      this.onTabChange(CurrentTab);
    },
  },
};
</script>
<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
table {
  border: 1px solid #e3e3e3;
}
thead {
  background-color: #fafafa;
}
td {
  color: #777777;
}
.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: #f3f3f3;
}
.clickable-action {
  cursor: pointer;
}
.clickable-action:hover {
  text-decoration: underline;
}
.pd-2 {
  padding: 0.19rem 1.3rem;
}
.float-right {
  float: right !important;
}
</style>
<style>
.table-amber,
.table-amber > td {
  background-color: #ffeabf;
}
.table-expired,
.table-expired > td {
  background-color: #dcd5d5;
  color: #4f5d73;
}
.selected-row {
  /* border: 2px groove #BA9EB0 !important */
  border: 0px groove #d3eda4 !important;
}
.selected-row-table {
  background-color: #d3eda4;
  color: #4f5d73;
}
.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
</style>
